.report-v2-panel-body-trendsbreakdown {
	background-color: transparent !important;
	padding: 0 !important;
}
.trends-breakdown-single {
	background: #ffff;
	padding: 20px;
	box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 7%);
	border-radius: 3px;
}
.trends-breakdown-info {
	display: flex;
	justify-content: space-between;
	align-items: end;
}
.trends-breakdown-info-left {
	h4 {
		font-size: 16px;
		font-weight: 600;
	}
	h6 {
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 0;
		font-weight: 600;
		svg {
			margin-left: 5px;
			color: #0E0E0B;
		}
	}
}
.trends-breakdown-info-right {
	h6 {
		font-size: 18px;
		display: flex;
		align-items: center;
		margin: 0;
	}
	svg {
		background-color: rgba(229, 224, 224, 0.29);
		box-shadow: 0px 0px 8px 0px rgb(107 107 107 / 20%);
		border-radius: 50%;
		padding: 3px;
		font-size: 25px;
		margin-right: 5px;
	}
}
.tbir-green {
	h6 {
		color: #8dc340;
	}
}
.tbir-dark-green {
	h6 {
		color: #028502;
	}
}
.tbir-light-green {
	h6 {
		color: #9FCB5C;
	}
}
.tbir-yellow {
	h6 {
		color: #F2DF3C;
	}
}
.tbir-orange {
	h6 {
		color: #FFB74E;
	}
}
.tbir-red {
	h6 {
		color: #DD0000;
	}
}
.tbir-blue {
	h6 {
		color: #3369D5;
	}
}
