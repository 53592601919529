.monthly-email-wrapper,
.monthly-email-export {
	display: flex;
	justify-content: center;
}
.monthly-email-export {
	button {
		background-color: #0E0E0B;
		border-color: #0E0E0B;
		font-size: 13px;
		font-weight: 600;
		height: auto;
		padding: 5px 10px;
		svg {
			margin-right: 5px;
		}
		&:hover{
			background-color: #22221E;
			border-color: #22221E;
		}
		&:focus{
			background-color: #0E0E0B;
			border-color: #0E0E0B;
		}
	}
}
.monthly-email-content {
	background: white;
	padding: 10px 0;
	margin-top: 30px;
	min-width: 40%;
	.key-metrics-circle {
		align-items: center;
		background-color: #fff;
		border: 3px solid;
		border-radius: 50%;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.251);
		color: #000;
		display: flex;
		flex-direction: column;
		font-weight: 700;
		height: 150px;
		justify-content: center;
		margin: 10px 5px;
		position: relative;
		width: 150px;
		h5 {
			font-size: 14px !important;
			margin-bottom: 10px !important;
			font-weight: 400 !important;
			text-transform: capitalize;
			letter-spacing: normal !important;
		}
		h2 {
			font-size: 22px !important;
			margin-bottom: 5px !important;
			line-height: 1 !important;
			font-weight: 500;
			margin-top: 0 !important;
		}
		h6 {
			display: flex !important;
			align-items: center !important;
			margin-bottom: 5px !important;
			svg {
				font-size: 20px !important;
				background-color: transparent !important;
				box-shadow: none !important;
				border-radius: 0 !important;
				padding: 0 !important;
			}
			span {
				font-size: 12px !important;
				margin-left: 5px !important;
				font-weight: normal !important;
			}
		}
		h4 {
			font-size: 10px !important;
			font-weight: 600;
		}
	}
}
.mec-single {
	padding: 20px;
}

.mec-title {
	text-align: center;
	img {
		height: 40px;
	}
	h2 {
		font-size: 30px;
		font-weight: 600;
	}
}
.mec-single-title {
	font-size: 20px;
	font-weight: 600;
	position: relative;
	padding-left: 10px;
	margin-bottom: 15px;
	&::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 3px;
		background-color: #0E0E0B;
		top: 0;
		left: 0;
	}
}
.mec-months {
	background: #0E0E0B;
	text-align: center;
	h4 {
		color: white;
		margin-bottom: 0;
		font-size: 20px;
		font-weight: 400;
		span {
			font-weight: 600;
		}
	}
}
.mec-total {
	text-align: center;
	ul {
		text-align: left;
		display: flex;
		justify-content: space-around;
		margin: 20px 0;
		li {
			padding-left: 20px;
			h5 {
				font-size: 24px;
				font-weight: 600;
				width: 140px;
				line-height: 1.2;
				margin-bottom: 5px;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					height: 100%;
					width: 3px;
					background: #8dc441;
					left: -20px;
				}
			}
			h4 {
				font-size: 25px;
				font-weight: 600;
				color: #8dc441;
				line-height: 1.2;
				margin-bottom: 5px;
			}
			span {
				font-size: 12px;
				width: 130px;
				display: block;
			}
		}
	}
}
.mec-single-text {
	a {
		color: black;
		margin-left: 3px;
		text-decoration: underline;
	}
}
.mec-single-list {
	position: relative;

	ul {
		padding: 50px 150px;
		li {
			padding: 10px 0;
			border-bottom: 2px solid #f3f3f3;
			h4 {
				font-size: 25px;
				font-weight: 600;
				span {
					color: #12a3d8;
				}
			}
			p {
				font-size: 15px;
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
	&::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 30px;
		background-color: #363631;
		top: 0;
		left: 0;
	}
	&::after {
		content: "";
		position: absolute;
		height: 30px;
		width: 100px;
		background: #0E0E0B;
		top: 0;
		left: 0;
	}
}
.mec-single-list-style {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		height: 100%;
		width: 30px;
		background-color: #363631;
		bottom: 0;
		right: 0;
	}
	&::after {
		content: "";
		position: absolute;
		height: 30px;
		width: 100px;
		background: #0E0E0B;
		bottom: 0;
		right: 0;
	}
}
.mec-metrics {
	display: flex;
	justify-content: space-between;
}

.mec-single-link {
	margin: 30px 0 10px;
	text-align: center;
	a {
		color: black;
		text-decoration: underline;
		font-weight: 600;
		font-size: 14px;
	}
}
.mec-facebook-wrapper-style {
	ul {
		padding: 50px 70px;
		li {
			display: flex;
			justify-content: space-between;
			h4 {
				font-size: 14px;
				span {
					font-size: 24px;
					color: #8dc441;
				}
			}
		}
	}
}
.mfl-single {
	width: 45%;
}
.monthly-email-footer {
	margin: 40px 0 20px;
	text-align: center;
	h6 {
		font-size: 14px;
		font-weight: 600;
		margin: 10px 0 0;
		a {
			margin-bottom: 10px;
			display: block;
			color: black;
			font-weight: normal;
			text-decoration: underline;
			font-size: 12px;
		}
	}
	p {
		a {
			color: black;
			margin-left: 3px;
			text-decoration: underline;
		}
	}
}
