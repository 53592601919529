.key-metrics-wrapper {
	display: flex;
	justify-content: space-evenly;
}
.key-metrics-circle {
	width: 200px;
	height: 200px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	color: black;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	border-radius: 50%;
	background-color: white;
	border: 10px solid #e4e4e4;
	position: relative;
	box-shadow: 0px 0px 7px 0px #00000040;
	text-align: center;
	h5 {
		color: #434244;
		text-transform: uppercase;
		margin: 0 auto;
		letter-spacing: 1px;
		font-size: 16px;
		font-weight: normal;
		max-width: 150px;
	}
	h2 {
		color: #434244;
		font-weight: 700;
		text-transform: uppercase;
		margin: 5px auto;
		letter-spacing: 1px;
		font-size: 20px;
	}
	h6 {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: center;
		margin: 0;
		svg {
			font-size: 25px;
		}
		span {
			margin-left: 5px;
		}
	}
}
.key-metrics-rate {
	font-size: 14px;
	position: relative;
	display: block;
}

.kmc-green {
	border-color: #8dc340 !important;
}
.kmc-blue {
	border-color: #0fc1ff !important;
}
.kmc-yellow {
	border-color: #f9e858 !important;
}
.kmc-red {
	border-color: #ff5231 !important;
}
.kmc-light-green {
	border-color: #c4e09a !important;
}
.kmc-orange {
	border-color: #ffb74e !important;
}
.kmc-dark-green {
	border-color: #013220 !important;
}
.green-graph {
	color: #8dc340;
}
.blue-graph {
	color: #0fc1ff;
}
.yellow-graph {
	color: #f9e858;
}
.red-graph {
	color: #ff5231;
}
.light-green-graph {
	color: #c4e09a;
}
.orange-graph {
	color: #ffb74e;
}
.dark-green-graph{
	border-color: #013220 !important;
	color: #013220 !important;
}
// .kmr-up {
// 	padding-left: 50px;
// 	&::before {
// 		content: " ";
// 		position: absolute;
// 		height: 22px;
// 		width: 60px;
// 		left: 32%;
// 		top: 18px;
// 	}
// }
// .kmr-down {
// 	padding-left: 50px;
// 	top: 22px;
// 	&::before {
// 		content: " ";
// 		position: absolute;
// 		height: 22px;
// 		width: 60px;
// 		left: 32%;
// 		top: -20px;
// 	}
// }
// .kmr-up-green {
// 	&::before {
// 		background: url(../../assets/images/light-green-up.png) no-repeat;
// 	}
// }
// .kmr-up-blue {
// 	&::before {
// 		background: url(../../assets/images/blue-up.png) no-repeat;
// 	}
// }
// .kmr-down-yellow {
// 	&::before {
// 		background: url(../../assets/images/yellow-down.png) no-repeat;
// 	}
// }
// .kmr-down-red {
// 	&::before {
// 		background: url(../../assets/images/red-down.png) no-repeat;
// 	}
// }
// .kmr-up-light-green {
// 	&::before {
// 		background: url(../../assets/images/light-green-up.png) no-repeat;
// 	}
// }
