.performance-graph-chart {
	display: inline-block;
	width: 250px;
	.highcharts-title {
       font-size: 12px !important;
	}
}
.highcharts-title {
	font-size: 14px !important;
}
