.mt-10 {
  margin-top: 10px !important;
}

.ds-flex {
  display: flex !important;
}

.ds-flex-js-center {
  display: flex !important;
  justify-content: center !important;
}

.ds-flex-js-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.btn {
  &__transparent {
    border: none;
    outline: none;
    color: #0e0e0b;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
    background-color: transparent;
  }
}