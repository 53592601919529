* {
  font-family: "Outfit", sans-serif;
  margin: 0;
  padding: 0;
}
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}
p {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Imports
@import "./ReportBlocksStyle/KeyMetrics.scss";
@import "./ReportBlocksStyle/NormalTable.scss";
@import "./ReportBlocksStyle/Donut.scss";
@import "./ReportBlocksStyle/Trends.scss";
@import "./ReportBlocksStyle/TrendsBreakdown.scss";
@import "./ReportBlocksStyle/Insights.scss";
@import "./ReportBlocksStyle/DeviceTable.scss";
@import "./ReportBlocksStyle/DataTable.scss";
@import "./ReportBlocksStyle/CustomReports.scss";
@import "./ReportBlocksStyle/Scorecard.scss";
@import "./ReportBlocksStyle/MonthlyEmail.scss";
@import "./ReportBlocksStyle/GADS.scss";
@import "./responsive.scss";

// Variables
$primary-blue: #974cd9;
$primary-green: #176f34;
$primary-black: #0e0e0b;
$primary-gray: #22221e;
$hover-blue: #744e92;
$secondary-color: #12a3d8;

// Custom scroll bar
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px whitesmoke;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #d9d5d5;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s linear;
}

::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

// Layout
.ant-table-content {
  font-size: 12px;
}
.ant-layout-header {
  position: fixed;
  left: 0;
  padding: 0;
  width: 100%;
  background: white !important;
  box-shadow: 2px 2px 7px 0px #e8e8e8;
  z-index: 100;
}
.ant-layout-sider {
  position: fixed;
  background: #ffffff !important;
  min-height: 100%;
  padding-top: 60px;
  box-shadow: 2px 2px 7px 0px #e8e8e8;
}
.ant-layout-content {
  margin: 65px 0 0 0;
  transition: 0.2s linear;
  padding: 20px;
  // background: transparent !important;
}
.ant-layout-sider-trigger {
  display: none;
}
.header-left {
  display: flex;
}
.header-impersonate {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: -15px;
  button {
    background-color: #b1b3b6;
    border-color: #b1b3b6;
    color: #fff;
    text-transform: uppercase;
    padding: 4px 40px;
    font-size: 14px;
    min-height: 40px;
    border-radius: 0;
    &:hover,
    &:focus {
      background-color: #939598;
      border-color: #939598;
      color: #fff;
    }
  }
}
.header-right {
  display: flex !important;
  justify-content: flex-end;
  ul {
    display: flex;
    li {
      display: flex;
      align-items: center;
      padding: 0 10px;
      button {
        border: none;
        box-shadow: none;
        background-color: transparent;
        height: 30px;
        width: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        font-size: 1.5em;
        color: $primary-blue;
      }
    }
  }
}
.header-notification {
  display: flex;
  align-items: center;
  a {
    display: inline-flex;
  }
  svg {
    font-size: 1.8em !important;
  }
  .ant-badge-count-sm {
    font-size: 10px !important;
  }
  .ant-badge-multiple-words {
    padding: 0px 5px;
  }
}
.header-notifications-wrapper {
  min-height: 200px;
  min-width: 500px;
  position: relative;
  margin: 0;
  padding: 10px 20px;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.header-logo {
  padding-right: 20px;
  img {
    width: 175px;
    height: 50px;
  }
}
.sider-toggle {
  position: fixed;
  left: 238px;
  top: 23px;
  z-index: 2000;
}
.header-feeback,
.header-profile {
  display: flex;
  align-items: center;
  button {
    border: none;
    box-shadow: none;
    &:active {
      border-color: transparent !important;
    }
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: transparent !important;
    background-color: transparent !important;
  }
}
.header-links,
.header-notification {
  padding: 0 20px !important;
}
.header-user-details {
  position: relative;
  margin: 0;
  padding: 10px;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  a {
    color: black;
    text-transform: uppercase;
    &:hover {
      color: $secondary-color;
    }
  }
  img {
    height: 50px;
    margin-right: 20px;
  }
}
.feedback-modal {
  .ant-modal-title {
    color: $primary-black;
    text-transform: uppercase;
    font-size: 22px;
  }
  label {
    font-size: 12px;
    font-weight: 600;
  }
  input {
    font-size: 12px;
  }
  .ant-upload {
    width: 100%;
    .ant-btn {
      width: 100%;
    }
  }
  .ant-btn-primary {
    background-color: $primary-gray;
    border-color: $primary-gray;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: $primary-black;
      border-color: $primary-black;
    }
  }
  .ant-modal-footer {
    display: none;
  }
}
.profile-modal {
  .ant-modal-title {
    color: $secondary-color;
    text-transform: uppercase;
  }
  label {
    font-weight: 600;
  }
  .ant-modal-footer {
    .ant-btn-primary {
      background-color: $primary-blue;
      border-color: $primary-blue;
    }
    .ant-btn-default {
      background-color: gray;
      border-color: gray;
      color: white;
    }
  }
}
.main-sidebar {
  .ant-menu-inline .ant-menu-item {
    padding: 20px;
  }

  .ant-layout-sider {
    min-width: 250px !important;
    max-width: 250px !important;
    width: 250px !important;
  }
  // .ant-layout-sider-collapsed {
  // 	min-width: 80px !important;
  // 	max-width: 80px !important;
  // 	width: 80px !important;
  // }
  .ant-menu-title-content {
    font-size: 12px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
  }
  .ant-menu-item-selected {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    background: #12a3d833 !important;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    content: none;
  }
}

.ant-select-item {
  width: 100% !important;
}
.ant-layout-footer {
  padding: 15px 20px;
  background: white;
  margin-left: 80px;
  transition: 0.2s linear;
  font-size: 12px;
}
.sider-length {
  transition: 0.2s linear;
}

.footer-left {
  svg {
    font-size: 0.7em;
    margin: 0 5px;
  }
}
.footer-right {
  p {
    text-align: right;
  }
}
.main-sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  ul {
    display: flex;
    justify-content: center;
    padding: 10px 30px;
    li {
      a {
        color: black;
        font-size: 16px;
        display: flex;
        align-items: center;
        span {
          font-size: 16px;
          color: #878780;
          margin-left: 5px;
        }
      }
    }
  }
}
.ant-layout-sider-collapsed .main-sidebar-footer ul {
  padding: 10px 0;
  li {
    padding: 10px 0;
    text-align: center;
    a {
      span {
        display: none;
      }
    }
  }
}
.ant-upload.ant-upload-select-picture-card {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50% !important;
  img {
    height: 100px;
    border-radius: 50%;
  }
}
.input-control {
  margin-bottom: 5px;
}
.input-filters {
  margin-bottom: 20px;
}
.Report-V2-filter-option {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.Report-V2-filter-box {
  background: #fff;
  padding: 5px 10px;
  min-height: 75px;
  min-width: 200px;
  position: relative;
  box-shadow: 0px 0px 5px 1px rgb(18 17 17 / 12%);
  border-radius: 5px;
  z-index: 90;
  .ant-select {
    width: 100%;
  }
  .ant-select-selector {
    border: none !important;
  }
}
.Report-V2-filter-option-title {
  display: block;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.react-bootstrap-daterangepicker-container {
  button {
    background-color: transparent;
    font-size: 12px;
    border: none;
  }
}
.Report-V2-filter-box-date {
  // max-width: 250px;
  max-width: 235px;
  height: 75px;
  position: relative;
  .ant-select {
    padding-right: 30px;
  }
  .ant-picker-input > input {
    font-size: 12px;
  }
  .ant-select-selector {
    position: absolute !important;
    top: -50px !important;
    background: #811d1d00 !important;
    z-index: 200;
  }
  .ant-select-selection-item {
    display: none;
  }
  .ant-select-arrow {
    // top: -42px;
    // right: -13px;
    top: -44px;
    right: -16px;
  }
}
.Report-V2-filter-box-date-wrapper {
  // padding-right: 10px;
  padding-right: 20px;
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
  .ant-form-item-control-input {
    min-height: 0 !important;
  }
}
// DATEPICKER
// .ant-picker-dropdown {
// 	top: 167px !important;
// }
// .ant-picker-range {
// 	padding: 0 5px 20px;
// }
.Report-V2-filter-box-date {
  .ant-picker {
    border: none;
    z-index: 20;
    cursor: pointer;
  }
  .ant-picker-focused {
    border-color: transparent;
  }
  .ant-picker-clear {
    right: 0 !important;
    top: 50%;
  }
}
.box-date-compare {
  position: absolute;
  left: 15px;
  bottom: 6px;
  font-size: 10px;
  color: gray;
}

.ant-picker-active-bar {
  display: none;
}
.Report-V2-filter-option-submit {
  button {
    background-color: $primary-black;
    border-color: $primary-black;
    &:hover {
      background-color: $primary-gray;
      border-color: $primary-gray;
    }
    &:focus {
      background-color: $primary-black;
      border-color: $primary-black;
    }
  }
}
.ant-select-item-option-content {
  font-size: 12px;
  text-transform: capitalize;
}
.ant-select-selection-item {
  font-size: 12px;
}
.daterange-custom-button {
  background: transparent;
  border: none;
  padding: 5px 12px;
  width: 100%;
  font-size: 0.9em;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}
.daterange-compare-toggle {
  padding: 5px 12px;
  button {
    margin-right: 5px;
  }
  span {
    font-size: 0.9em;
  }
}
.additonal-preset-range {
  font-size: 0.9em;
  padding: 5px 12px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}
.inputrange-calendar {
  top: 0 !important;
  left: 0 !important;
}
.apr-active {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e6f7ff;
}
// LOGIN
.login-main {
  margin: 0 !important;
  padding: 0 !important;
}
.login-left,
.login-right {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9fafc;
  padding: 50px;
}

.login-left-image {
  text-align: center;
}
.login-left-text {
  h3 {
    font-size: 30px;
    text-align: center;
    margin: 15px 0 30px;
  }
  ul {
    li {
      position: relative;
      padding-left: 70px;
      padding-bottom: 20px;
      img {
        position: absolute;
        height: 30px;
        width: 50px;
        left: 0;
        object-fit: contain;
      }
      h5 {
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }
}

.login-form {
  padding: 30px;
  max-width: 650px;
  background: white;
  position: relative;
  box-shadow: 0px 0px 7px 0px #cdcccc4d;
  button {
    width: 100%;
    background-color: $primary-blue;
    border-color: $primary-blue;
    text-transform: uppercase;
    padding: 10px;
    height: auto;
    &:hover {
      background-color: $hover-blue;
      border-color: $hover-blue;
    }
  }
  ::-webkit-input-placeholder {
    color: gray;
    font-size: 12px;
  }
  .ant-form-item {
    min-width: 350px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    font-size: 12px;
  }
  .login-form-forgot {
    margin-left: 2px;
    color: $primary-black;
    text-decoration: underline;
  }
}
.login-right-image {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid $primary-blue;
  border-radius: 50%;
  padding: 5px;
  background: white;
  img {
    height: 50px;
    width: 50px;
  }
}
.login-forgot-link .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}

// REPORT CSS
.report-v2-panel {
  margin-bottom: 20px;
}
.report-v2-panel-heading {
  margin-bottom: 10px;
  min-height: 22px;
  h2 {
    margin-bottom: 0;
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    span {
      position: absolute;
      margin-left: 5px;
      top: 2px;
      cursor: pointer;
      svg {
        font-size: 15px;
        color: $primary-black;
      }
    }
  }
}
.heading-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
    padding: 8px !important;
    ul {
      padding-left: 20px;
      li {
        list-style: disc;
      }
    }
  }
}
.report-v2-panel-body {
  background: white;
  padding: 20px;
  min-height: 200px;
  .ant-pagination-item-active {
    background: $primary-black;
    border-color: $primary-black;
  }
  .ant-pagination-item a {
    font-size: 12px;
    &:hover {
      color: $primary-black;
    }
  }

  .ant-pagination-item-active a {
    color: white;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    height: 28px;
    line-height: 25px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      &:hover {
        background-color: #22221e;
        color: #b6b6b6;
      }
    }
  }
  .ant-pagination-item:hover {
    border-color: $primary-black;
    a {
      color: #0e0e0b;
    }
  }
  .ant-pagination-item-active a {
    color: white !important;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #0e0e0b;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab {
    font-size: 12px;
    padding: 6px 12px;
    min-width: 75px;
    background-color: #d7d7cc;
    display: flex;
    justify-content: center;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab-active {
    background-color: $primary-black;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
  .ant-tabs-tab:hover {
    color: initial;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  button {
    &:hover {
      background-color: #689439;
      border-color: #689439;
      color: white;
    }
    &:focus {
      background-color: #53861e;
      border-color: #53861e;
      color: white;
    }
  }
}

.performance-graph-chart-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12%;
  width: 100%;
  overflow: hidden;
}
.performance-graph-charts {
  h2 {
    margin-bottom: 10px;
    min-height: 22px;
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }
}
// .performance-datatable {
// 	margin-top: 20px;
// 	overflow-y: scroll;
// 	table {
// 		width: 100%;
// 		text-align: left;
// 		border-radius: 2px 2px 0 0;
// 		border-collapse: separate;
// 		border-spacing: 0;

// 		thead {
// 			display: table-header-group;
// 			vertical-align: middle;
// 			border-color: inherit;

// 			th {
// 				position: relative;
// 				color: #000000d9;
// 				font-weight: 500;
// 				text-align: left;
// 				background: #fafafa;
// 				border-bottom: 1px solid rgba(0, 0, 0, 0.06);
// 				transition: background 0.3s ease;
// 				padding: 16px;
// 				overflow-wrap: break-word;
// 				text-align: center;
// 			}
// 		}
// 		td {
// 			display: table-cell;
// 			vertical-align: inherit;
// 			border-right: 1px solid rgba(0, 0, 0, 0.06);
// 			text-align: center;
// 		}
// 		td:first-child,
// 		th:first-child {
// 			border-left: 1px solid rgba(0, 0, 0, 0.06);
// 			text-align: left;
// 		}
// 		thead tr {
// 			text-transform: uppercase;
// 		}
// 		tr {
// 			display: table-row;
// 			vertical-align: inherit;
// 			border-color: inherit;
// 		}
// 		tbody {
// 			display: table-row-group;
// 			vertical-align: middle;
// 			border-color: inherit;
// 			td {
// 				border-bottom: 1px solid rgba(0, 0, 0, 0.06);
// 				transition: background 0.3s;
// 				position: relative;
// 				padding: 8px;
// 				overflow-wrap: break-word;
// 			}
// 		}
// 	}
// 	svg {
// 		color: red;
// 		margin-right: 5px;
// 	}
// }

.highcharts-credits {
  display: none !important;
}

// DATATABLE CSS
.ant-table {
  font-size: 12px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px;
}
.ant-table th {
  text-transform: uppercase;
}
.table-header-filter {
  ul {
    display: flex;
  }
}
.ant-table-summary {
  td {
    background: #fafafa;
    font-weight: 600;
  }
}

// ACCOUNT PAGE
.panel-heading {
  padding: 10px 0;
}
.panel-heading-title {
  display: flex;
  h2 {
    margin: 0;
    font-size: 2em;
    text-transform: uppercase;
    color: $primary-blue;
  }
}
.panel-heading-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    background-color: $primary-blue;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    height: auto;
    border-radius: 2px !important;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover {
      background-color: $hover-blue;
      border-color: $hover-blue;
      color: white;
    }
    &:focus {
      background-color: #f0f0e6;
      border-color: #f0f0e6;
      color: #afafa7;
    }
  }
}
.panel-body-input {
  margin-bottom: 10px;
}
.panel-body-input-left {
  display: flex;
  flex-wrap: wrap;
}
.panel-body-input-right {
  display: flex;
  justify-content: flex-end;
}
.panel-body-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-input-affix-wrapper,
  .ant-btn-icon-only {
    border: none;
    height: 32px;
  }
  .ant-checkbox-inner {
    border-color: $primary-black;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-black;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-gray;
  }
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
}
.panel-body-select {
  margin: 5px 0;
  &:not(:last-child) {
    padding-right: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-btn-icon-only {
    height: 40px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }
}

.panel-body-content {
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table table {
    border-bottom: 4px solid $secondary-color;
  }
  .ant-table-thead tr th {
    background-color: $secondary-color;
    color: white;
  }
  tbody > tr:nth-of-type(odd) {
    background-color: #12a3d814;
  }
  ant-table-column-sort {
    background: transparent !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-pagination-item-active {
    background: $primary-blue;
    border-color: $primary-blue;
  }
  .ant-pagination-item a {
    font-size: 12px;
  }

  .ant-pagination-item-active a {
    color: white;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    height: 28px;
    line-height: 25px;
  }
  .ant-pagination-item:hover {
    border-color: $primary-black;
  }
  .ant-pagination-item:hover a {
    color: $primary-black;
  }
  .ant-switch {
    min-width: 40px;
    height: 18px;
  }
  .ant-switch-checked {
    background: $primary-black;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 17px - 0px);
  }
  .ant-switch-handle {
    top: 2px;
    width: 13px;
    height: 13px;
  }
}
.panel-action-button {
  display: inline-flex;
  align-items: center;
  a,
  span {
    background: $primary-blue;
    height: 25px;
    width: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    svg {
      color: white;
      font-size: 13px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.report-notification {
  min-width: 280px;
}
.report-notification-wrapper {
  float: right;
  display: flex !important;
  padding: 0 !important;
  span {
    background: #ff6262;
    color: #fff;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    padding: 4px 0 !important;
    display: inline-block;
    text-align: center;
    float: none;
    margin-right: 4px;
    margin-top: 0;
    line-height: 0.9;
  }
  a {
    padding: 0 !important;
  }
  .noti-orange {
    background-color: #ffc803;
  }
  .noti-blue {
    background-color: #278ee6;
  }
  .severity-red {
    background-color: #dd4b39;
  }
  .severity-blue {
    background-color: #b0c4de;
  }
  .severity-yellow {
    background-color: #ffbf00;
  }
}
.user-note {
  color: $primary-black;
  font-size: 16px;
  cursor: pointer;
}
.user-details-modal {
  .ant-modal-title {
    color: $primary-black;
    font-size: 22px;
  }
  .ant-btn {
    background-color: $primary-gray;
    border-color: transparent;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    svg {
      font-size: 18px;
      margin-right: 5px;
    }
    &:hover,
    &:focus {
      background-color: $primary-black;
      border-color: $primary-black;
      color: white;
    }
  }
}
.user-details-table {
  width: 100%;
  border-bottom: 4px solid $secondary-color;
  font-size: 12px;
  td {
    padding: 8px;
  }
  tr:nth-of-type(odd) {
    background-color: #12a3d814;
  }
}
// Account details
.account-details-info {
  margin-right: 20px;
  ul {
    li {
      display: flex;
      justify-content: space-between;
      padding: 6px 12px;
      &:nth-child(odd) {
        background: #12a3d814;
      }
      span {
        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
}
.account-details-upper {
  margin-top: 10px;
}
.account-details-buttons {
  span {
    display: block;
    text-align: right;
    font-weight: 600;
  }
  ul {
    li {
      text-align: right;
      padding: 6px 0;
      a {
        font-size: 12px;
      }
    }
  }
  .ant-switch {
    min-width: 40px;
    height: 18px;
  }
  .ant-switch-checked {
    background: $primary-blue;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 17px - 0px);
  }
  .ant-switch-handle {
    top: 2px;
    width: 13px;
    height: 13px;
  }
}
.account-details-lower {
  margin-top: 20px;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px 8px;
  }
  .ant-badge-status-dot {
    height: 15px;
    width: 15px;
  }
}
.table-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: flex;
    background: white;
    border-radius: 50%;
    font-size: 14px;
    margin-left: 3px;
    cursor: pointer;
    svg {
      color: $primary-black;
    }
  }
}
.table-tooltip-specialist {
  justify-content: flex-start;
}
.report-v2-filter-buttons-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.accsetting-btn {
  button {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    &:hover {
      background: $primary-black;
    }
    &:active,
    &:focus {
      background: transparent !important;
    }
  }
}
.ad-passive {
  cursor: not-allowed !important;
}
.ad-disabled {
  background: #8dc44147 !important;
  pointer-events: none;
}
.as-diabled {
  pointer-events: none;
}
.as-passive {
  display: inline-block;
  cursor: not-allowed;
  span {
    background: #8dc44147 !important;
  }
}
// EXPORT BUTTON
.export-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  button {
    background-color: $primary-black;
    border-color: $primary-black;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 12px;
    height: initial;
    svg {
      margin-right: 5px;
      font-size: 10px;
    }
    &:hover,
    &:focus {
      background-color: $primary-gray;
      border-color: $primary-gray;
    }
  }
}
.export-dropdown {
  li {
    font-size: 12px;
    padding: 3px 10px;
    button {
      padding: 0;
      border: none;
    }
    svg {
      margin-right: 5px;
      font-size: 10px;
    }
  }
}
// HEADER RIGHT BUTTON
.report-v2-filter-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 5px 0 5px 15px;
  box-shadow: 0px 0px 5px 1px rgb(18 17 17 / 12%);
  border-radius: 5px;
  margin-bottom: 10px;
  button {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    color: $primary-blue;
    margin: 0 5px;
    &:hover {
      border-color: transparent;
      color: $primary-gray;
    }
    &:focus {
      background-color: transparent;
      color: $primary-gray;
      border-color: transparent;
    }
  }
  .ant-switch-checked {
    background: $primary-black;
    padding: 0 !important;
    margin-right: 11px;
    &:focus {
      background-color: $primary-black;
    }
  }
  .ant-btn {
    padding: 4px 10px;
  }
}
.report-comment-modal {
  .ant-modal-title {
    color: $primary-black;
    font-size: 22px;
    text-transform: uppercase;
  }
}
.report-comment-editor {
  margin-bottom: 20px;
  .ql-editor {
    min-height: 150px;
    white-space: pre-line;
  }
}
.report-comment-buttons {
  margin-bottom: 15px;
  .ant-switch-checked {
    background: $primary-black;
  }
  .rcb-title {
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
  }
  .ant-col {
    display: flex;
    align-items: center;
  }
  .ant-select {
    font-size: 12px;
    text-transform: capitalize;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 27px;
  }
}

.rcb-middle {
  display: flex;
  align-items: center;
}
.tags-add {
  padding-bottom: 0;
  input {
    font-size: 12px;
  }
  button {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    border-radius: 0;
    background-color: #e2e1e1;
    height: 28px;
  }
}
.report-comment-save {
  display: flex;
  justify-content: flex-end;
  .main-primary-btn {
    background-color: $primary-black;
    color: white;
    border-color: $primary-black;
    &:hover,
    &:active,
    &:focus {
      background-color: $primary-gray;
      border-color: $primary-gray;
    }
  }
}
.help-modal-title {
  background: $primary-black;
  color: white;
  padding: 2px 10px;
  border-radius: 50px;
  svg {
    font-size: 12px;
    margin-right: 5px;
    color: white;
  }
}
.help-modal-title-btn {
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: transparent;
  }
}

.Report-V2-filter-option .ant-form-item {
  margin-bottom: 0 !important;
}
.comment-settings-modal {
  .ant-modal-title {
    color: $primary-black;
    text-transform: uppercase;
    font-size: 22px;
  }
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-btn-default {
    background-color: gray;
    border-color: gray;
    color: white;
    &:hover {
      background-color: #d0d0d0;
      border-color: #d0d0d0;
    }
  }
  .ant-btn-primary {
    background-color: $primary-black;
    border-color: $primary-black;
    box-shadow: none;
    &:hover {
      background-color: $primary-gray;
      border-color: $primary-gray;
    }
    &:focus {
      background-color: #689439;
      border-color: #689439;
    }
  }
}
// ACCOUNT ADVANCED SETTINGS
.account-advanced-setting-form-wrapper {
  background: #ffffff;
  padding: 20px;
  .ant-form label {
    font-size: 12px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-black;
  }
  .ant-radio-inner::after {
    background-color: $primary-black;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $primary-black;
    background-color: $primary-black;
  }
}
.adv-set-form-wrapper {
  display: flex;
}
.adv-set-form-single {
  padding: 10px;
  width: 100%;
  margin: 10px;
  border: 1px solid #0000000d;
  h2 {
    font-size: 14px;
    font-weight: 600;
  }
}
.asfs-wrapper {
  padding-left: 20px;
}
.currency-single {
  padding: 0 15px;
}
.currency-single-title {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}
.adv-set-form-single-upload {
  button {
    background-color: $primary-black;
    border-color: $primary-black;
    color: white;
    &:hover,
    &:focus {
      background-color: $primary-gray;
      border-color: $primary-gray;
      color: white;
    }
  }
}
#advanced-settings_budget_goals_type {
  display: flex;
  label {
    display: block;
  }
}
.adv-set-form-budget {
  .ant-form-item {
    margin-bottom: 12px;
  }
}
.budget_goals_combined_services-wrapper {
  display: flex;
  position: relative;
  margin-left: 170px;
  .budget-goals-title {
    position: absolute;
    right: 0;
    top: -15px;
    font-size: 12px;
  }
}
#advanced-settings_budget_goals_combined_services label {
  display: inline-flex;
  width: 100%;
  margin-bottom: 6px;
}
#advanced-settings_budget_goals_master_service .ant-space-vertical {
  gap: 6px !important;
}
#advanced-settings_custom_reports {
  height: 300px;
  overflow-y: scroll;
  width: 100%;
  label {
    display: flex;
    width: fit-content;
    margin-bottom: 6px;
  }
}
.adv-set-note {
  margin: 10px;
  position: relative;
  padding: 10px 10px 10px 20px;
  p {
    font-weight: 600;
    font-size: 12px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background-color: $primary-black;
  }
}
.logo-preview,
.csv-preview {
  margin-top: 15px;
}
.adv-set-form-wrapper-button {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 10px;
  button {
    background-color: $primary-gray;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover {
      background-color: $primary-black;
      border-color: $primary-black;
      color: white;
    }
  }
}
// .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
// 	background: $primary-blue;
// }

// DATA SOURCES
.ant-collapse-item-active .ant-collapse-header {
  background: $secondary-color;
  color: white !important;
}
.panel-body-datasources {
  .ant-collapse {
    border: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border-left: 5px solid #12a3d8;
    background: #12a3d80f;
  }
  .ant-collapse-item-active .ant-collapse-header {
    background: #12a3d8 !important;
    color: white !important;
    padding: 20px 16px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 16px;
  }
  .ant-collapse > .ant-collapse-item {
    border: none !important;
    margin-bottom: 20px;
  }
  .ant-collapse-extra {
    .ant-switch {
      min-width: 40px;
      height: 18px;
    }
    .ant-switch-checked {
      background: $primary-blue;
    }
    .ant-switch-checked .ant-switch-handle {
      left: calc(100% - 17px - 0px);
    }
    .ant-switch-handle {
      top: 2px;
      width: 13px;
      height: 13px;
    }
  }
}
.collapsible-inner {
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav-wrap {
    padding-left: 15%;
  }
  .ant-tabs-nav-list {
    border: 2px solid $primary-blue;
    border-radius: 50px;
    overflow: hidden;
  }
  .ant-tabs-tab {
    padding: 10px 50px;
    font-size: 12px;
    &:nth-child(2) {
      border-left: 2px solid $primary-blue;
      border-right: 2px solid $primary-blue;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-shadow: 0 0 0.25px currentcolor;
  }
  .ant-tabs-tab-active {
    background-color: $primary-blue;
    border-bottom-color: $primary-blue;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab:hover {
    color: currentColor;
  }
  .ant-tabs-tab-btn {
    color: $primary-blue;
    font-weight: 600;
  }
  .collapsible-inner-btn {
    button {
      font-size: 12px;
      padding: 5px 10px;
      font-weight: 600;
      display: flex;
      align-items: center;
      svg {
        margin-right: 3px;
      }
    }
    .ant-form-item-control-input-content {
      display: flex;
    }
  }
  .collapsible-inner-tooltip {
    color: $primary-blue;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .collapsible-save-btn {
    background-color: $primary-blue;
    border-color: $primary-blue;
  }
  .collapsible-reset-btn {
    background-color: #f39c12;
    border-color: #f39c12;
  }
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-switch {
    min-width: 40px;
    height: 18px;
  }
  .ant-switch-checked {
    background: $primary-blue;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 17px - 0px);
  }
  .ant-switch-handle {
    top: 2px;
    width: 13px;
    height: 13px;
  }
}

// MONTHLY EMAIL REPORT
.email-sent-to {
  border: none;
  border-radius: 0;
  ul {
    li {
      font-size: 12px;
      padding: 5px 0 !important;
      border-bottom: none !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // svg {
      // 	color: $primary-blue;
      // }
      span {
        margin-left: 5px;
      }
    }
  }
  .ant-list-empty-text {
    padding: 5px;
  }
  .ant-empty-normal .ant-empty-image {
    height: 20px;
  }
  .ant-empty {
    margin: 0;
  }
}

// CONTACTS
.impersonate-start-btn {
  background-color: $primary-black;
  border-color: $primary-black;
  color: white;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  height: auto;
  &:hover {
    background-color: $primary-gray;
    border-color: $primary-gray;
    color: white;
  }
}

// ACCOUNT NOTIFICATION
.panel-body-content-acc-notification {
  .ant-badge-status-dot {
    height: 20px;
    width: 20px;
  }
}
.account-notification-sub {
  display: flex;
  .ant-checkbox-wrapper {
    margin-right: 5px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $primary-black;
      border-color: $primary-black;
    }
  }
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: #f0f0e6;
      border-color: #f0f0e6;
    }
  }
}

// REPORT FINE TUNING
.trends-datatable {
  font-size: 12px;
  overflow-y: auto;
}

// DATERANGE PICKER FINE TUNING
// Removed report v2 filter box css
.Report-V2-filter-box-date {
  .ant-picker-range,
  .ant-picker {
    padding: 0;
    display: flex;
  }
}
.main-daterange {
  input {
    font-size: 12px !important;
  }
}
.compare-daterange {
  input {
    font-size: 10px !important;
    color: gray;
  }
}
.ant-picker:hover,
.ant-picker-focused {
  box-shadow: none !important;
}
.header-dropdown {
  padding-left: 60px;
  .ant-select {
    &:first-child {
      min-width: 200px;
      margin-right: 20px;
    }
    &:last-child {
      min-width: 250px;
    }
  }
}

// ACCOUNT SERVICE SETTINGS
.service-setting-form {
  .ant-modal-title {
    text-transform: uppercase;
    color: $primary-black;
    font-size: 22px;
  }
  .ant-modal-body {
    padding: 24px 20px;
  }
  .ant-row {
    padding: 0 4px;
  }
  .ssf-5 {
    width: 20%;
    display: inline-flex;
  }
  .ssf-6 {
    width: 25%;
    display: inline-flex;
  }
  .ssf-12 {
    width: 50%;
    display: inline-flex;
  }
  .ssf-18 {
    width: 75%;
    display: inline-flex;
  }
  .ant-form-item-label > label {
    font-size: 12px;
  }
  .ant-form label {
    font-size: 12px;
  }
  .ant-input,
  .ant-picker-input > input {
    font-size: 12px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-black;
    border-color: $primary-black;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-black;
  }
  .ant-radio-inner::after {
    background-color: $primary-black;
  }
  .ant-typography {
    color: $primary-black;
    margin-top: 10px;
  }
  .ant-modal-footer {
    button {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }
    .ant-btn-primary {
      color: white;
      background-color: $primary-gray;
      border-color: $primary-gray;
      &:hover {
        background-color: $primary-black;
        border-color: $primary-black;
        color: white;
      }
    }
    .ant-btn-default {
      background-color: #d7d7cc;
      border-color: #fffef4;
      color: $primary-black;
      &:hover {
        background-color: #fffef4;
        border-color: #fffef4;
      }
    }
  }
  .mf-sym {
    display: inline-block;
    margin-top: 4px;
  }
  .read-only-input {
    background: #f3f3f3;
    cursor: not-allowed;
  }
}
.default-form-single {
  display: flex;
  .ant-form-item-label {
    min-width: 190px;
    text-align: left;
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    position: relative;
    input {
      max-width: 80px;
      margin: 0 10px;
    }
    span {
      position: absolute;
    }
  }
}
.service-variables-title {
  margin-bottom: 10px;
  font-size: 12px;
}
.service-variables-input {
  margin-bottom: 15px;
  input {
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    height: 28px;
    width: 140px;
    font-size: 12px;
    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      border-right-width: 1px;
      outline: 0;
    }
  }
}
.addTierBtn {
  background-color: $primary-black;
  border-color: $primary-black;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px 10px;
  height: 30px;
  margin-bottom: 10px;
  &:hover {
    background-color: $primary-gray;
    border-color: $primary-gray;
    color: white;
  }
}
.service-variables-btn {
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    svg {
      color: #da0909 !important;
      font-size: 16px;
    }
  }
}
.combined-services-tips {
  margin-bottom: 10px;
  ul {
    li {
      font-size: 12px;
      font-weight: 600;
      position: relative;
      padding-left: 15px;
      &::before {
        content: ".";
        position: absolute;
        font-size: 30px;
        top: -23px;
        left: 0;
      }
    }
  }
}
.fees-alert-message {
  margin-top: 10px;
  font-size: 12px;
}
.service-fee-readonly {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    background: #3636360f;
    left: -20px;
    top: -5px;
    height: 103%;
    z-index: 2;
    width: 106%;
    cursor: not-allowed;
  }
}

// MONICA
.daterange-picker-addition .ant-select-item-empty {
  display: none;
}

// UPDATE FROM SALESFORCE
.import-modals {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-alert {
    margin-top: 20px;
  }
  .ant-upload {
    width: 100%;
    button {
      width: 100%;
      font-size: 12px;
      text-align: left;
    }
  }
  .ant-form-item-label > label {
    min-width: 150px;
    margin-right: 30px;
  }
  .ant-modal-title {
    color: $primary-black;
    font-size: 22px;
  }
  .ant-modal-footer {
    .ant-btn-default {
      background-color: $primary-gray;
      border-color: $primary-gray;
      color: white;
      &:hover {
        background-color: $primary-black;
        border-color: $primary-black;
      }
    }
  }
}
.import-modals-form-buttons {
  display: flex;
  justify-content: space-between;

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
  button,
  a {
    background-color: $primary-black;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      margin-right: 5px;
    }
    &:hover,
    &:focus {
      background-color: $primary-gray;
      border-color: $primary-gray;
      color: white;
    }
  }
}
.history-modal {
  @extend .panel-body-content;
  .ant-modal-title {
    color: $primary-black;
  }
  .ant-modal-footer .ant-btn-default {
    background-color: $primary-gray;
    border-color: $primary-gray;
    color: white;
  }
}
.upload-search-term {
  .ant-form-item {
    margin-bottom: 20px;
  }
  .import-modals-form-buttons {
    margin-bottom: 0;
  }
  .ant-select.ant-select-in-form-item {
    width: 100%;
  }
  .ant-typography {
    margin-bottom: 20px;
    display: block;
    font-size: 12px;
    color: #9b9a9a;
  }
}
.ptc-modal-form {
  position: relative;
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-input {
    font-size: 12px;
  }
}
.ptc-upper {
  padding-right: 40px;
  .ant-space-item {
    width: 100%;
  }
  .ant-select.ant-select-in-form-item {
    width: 100% !important;
  }
}
.ptc-lower {
  align-items: center;
  .ant-space-item:not(:last-child) {
    width: 100%;
  }
}
.ptc-add {
  position: absolute;
  top: 30px;
  right: 0;
}

.ptc-button {
  border: none;
  box-shadow: none;
  svg {
    font-size: 23px;
    color: #dd4b39;
  }
}
.ptc-add-btn {
  svg {
    color: #8dc441;
  }
}
.page-type-category {
  .ant-modal-title {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    svg {
      color: $primary-black;
    }
  }
  .ant-modal-footer {
    .ant-btn-default {
      background-color: $primary-gray;
      border-color: $primary-gray;
      color: white;
      &:hover {
        background-color: $primary-black;
        border-color: $primary-black;
      }
    }
  }
}

.activities-title {
  color: white;
  background: $primary-black;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
  padding: 8px;
  margin-top: 0.5em;
}
.log-message {
  th {
    background-color: #12a3d8;
    color: white;
    padding: 8px;
    font-weight: 500;
    text-align: left;
  }
  td {
    padding: 8px;
  }
}
.modal-back-btn {
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 10px;
  padding: 0;
  box-shadow: none;
  height: auto;
  color: $primary-gray;
  font-size: 12px;
  text-transform: uppercase;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: $primary-black;
  }
}
.upload-modal-btn {
  border: none;
  padding: 0;
  color: $primary-black;
  height: 25px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
}

// ROLES EDIT
.roles-view-wrapper {
  background: white;
  padding: 20px;
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-black;
  }
  .ant-radio-inner::after {
    background-color: $primary-black;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $primary-black;
    background-color: $primary-black;
  }
}
.role-status-left {
  label {
    font-weight: 600;
    margin-right: 10px;
    font-size: 1.2em;
  }
  .ant-switch {
    min-width: 40px;
    height: 20px;
  }
  .ant-switch-checked {
    background: $primary-black;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 17px - 0px);
  }
  .ant-switch-handle {
    top: 2px;
    width: 15px;
    height: 15px;
  }
}
.role-status-right {
  display: flex;
  .rsr-title {
    margin-right: 20px;
    font-weight: 600;
    font-size: 1.2em;
  }
  label {
    margin-right: 20px;
  }
  span {
    font-size: 12px;
  }
  .ant-radio-group,
  .ant-checkbox-group {
    label {
      width: 100%;
      display: flex;
    }
  }
}
.roles-status-row {
  display: grid;
}
.role-status-wrapper {
  border-bottom: 1px solid #b0b0b061;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.roles-display-table {
  tbody {
    text-transform: capitalize;
  }
}
.pbc-upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  h2 {
    font-weight: 600;
    font-size: 1.2em;
  }
  button {
    background-color: $primary-gray;
    border-color: $primary-black;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      margin-right: 5px;
    }
    &:hover,
    &:focus {
      background-color: $primary-black;
      border-color: $primary-black;
      color: white;
    }
  }
}
.add-user-modal {
  @extend .panel-body-content;
  .ant-modal-title {
    color: $primary-black;
    text-transform: uppercase;
    font-size: 22px;
  }
  .ant-modal-footer .ant-btn:last-child {
    background-color: $primary-gray;
    border-color: transparent;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    svg {
      font-size: 18px;
      margin-right: 5px;
    }
    &:hover,
    &:focus {
      background-color: $primary-black;
      border-color: $primary-black;
      color: white;
    }
  }
}

// EDIT USER
.user-update-form-wrapper {
  background-color: white;
  padding: 10px;
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-input {
    font-size: 12px;
  }
  .ant-form-item-control-input-content {
    font-size: 12px;
  }
  .ant-checkbox-group {
    label {
      width: 100%;
      display: flex;
      font-weight: normal;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-black;
  }
  .ant-radio-inner::after {
    background-color: $primary-black;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $primary-black;
    background-color: $primary-black;
  }
}
.user-update-single-wrapper {
  padding: 10px;
}
.user-update-single {
  border: 1px solid rgba(0, 0, 0, 0.051);
  padding: 10px;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.user-update-single-wrapper-button {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0;
  .ant-form-item {
    margin-bottom: 0;
  }
  button {
    background-color: $primary-gray;
    border-color: transparent;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    svg {
      font-size: 18px;
      margin-right: 5px;
    }
    &:hover,
    &:focus {
      background-color: $primary-black;
      border-color: $primary-black;
      color: white;
    }
  }
}
.user-accounts {
  display: flex;
  background: #fbfbfb;
  p {
    font-size: 12px;
  }
  .ant-typography {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-list {
    width: 100%;
    margin-left: 20px;

    li {
      font-size: 12px;
      padding: 6px 3px;
      border: none;
      color: #737373;
    }
  }
  .ant-list-header {
    padding: 0;
    border-bottom: none;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    padding-top: 6px;
  }
  .ant-list-items {
    max-height: 300px;
    overflow-y: scroll;
  }
}
.pdf-extra-info {
  margin-bottom: 20px;
  position: relative;
  img {
    position: absolute;
    right: 0;
    top: 0;
    height: 80px;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
  h4 {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
  }
  ul {
    li:not(:last-child) {
      padding-bottom: 5px;
    }
  }
}
.ant-back-top {
  right: 20px;
  bottom: 20px;
  font-size: 25px;
  opacity: 0.3;
  transition: 0.2s linear;
  color: #000000bf;
  &:hover {
    opacity: 1;
  }
}
.Report-V2-filter-box-autocomplete {
  max-width: 200px;
  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    font-size: 12px;
  }
}

// ADMIN TOOLS
.alert-wrapper {
  h5 {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-black;
    border-color: $primary-black;
  }
}
// .alert-old {
// 	background-color: gray !important;
// }
.alert-editor {
  border: 1px solid rgb(133 133 133 / 12%);
  box-shadow: 0px 0px 9px 2px rgb(201 201 201 / 10%);
  .ql-toolbar {
    padding: 10px 20px;
    background: #d7d7cc;
    margin-bottom: 0;
    border-bottom: 1px solid #8585855e;
  }
  .ql-container {
    background: white;
  }
  .ql-editor {
    min-height: 200px;
  }
}
.alert-checkbox {
  margin: 20px 0;
  .ant-checkbox-wrapper {
    font-size: 13px;
  }
}
.alert-save {
  background-color: $primary-black;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:hover {
    background-color: $primary-gray;
    border-color: $primary-gray;
    color: white;
  }
  &:focus {
    background-color: $primary-gray;
    border-color: $primary-gray;
    color: white;
  }
}
.archive-list-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}
.archive-list {
  border: 1px solid #f0f0e6;
  padding: 10px;
  margin-bottom: 20px;
  &:nth-child(odd) {
    background-color: #f0f0e6;
  }
}
.al-date {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  span {
    margin-right: 10px;
  }
  strong {
    background-color: $primary-black;
    color: white;
    font-weight: normal;
    padding: 2px 5px;
  }
}
.al-details {
  a {
    color: $primary-black;
    text-decoration: underline;
  }
}
.google-updates {
  .panel-body-content {
    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
  .pbc-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    h2 {
      font-size: 15px;
    }
    button {
      background-color: $primary-blue;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;

      &:hover {
        background-color: #689439;
        border-color: #689439;
        color: white;
      }
      &:focus {
        background-color: #53861e;
        border-color: #53861e;
        color: white;
      }
    }
  }
}
.google-update-modal {
  .ant-modal-title {
    color: $secondary-color;
    text-transform: uppercase;
  }
}
#editCategory {
  label {
    min-width: 75px;
    font-size: 12px;
  }
  .ant-avatar {
    display: block;
    margin-bottom: 15px;
  }
  .ant-upload {
    button {
      text-transform: uppercase;
      font-size: 12px;
      margin-right: 15px;
    }
  }

  .ant-checkbox-wrapper {
    font-size: 12px;
  }
}
#editUpdate {
  label {
    min-width: 100px;
    font-size: 12px;
  }
  .ant-select.ant-select-in-form-item,
  input,
  .ant-picker {
    width: 60%;
    font-size: 12px;
  }
}
.editUpdate-editor {
  border: 1px solid #8585855e;
  .rdw-editor-toolbar {
    padding: 10px 20px;
    background: #e8eaee;
    margin-bottom: 0;
    border-bottom: 1px solid #8585855e;
  }
  .rdw-option-wrapper {
    background: #e8eaee;
  }
  .rdw-dropdown-wrapper {
    .rdw-dropdownoption-default,
    a {
      color: black;
      font-size: 12px;
    }
  }
  .rdw-editor-main {
    padding: 10px 20px;
    background: white;
  }
}
.google-edit-submit {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $primary-blue;
      border-color: $primary-blue;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      &:hover {
        background-color: #689439;
        border-color: #689439;
        color: white;
      }
      &:focus {
        background-color: #53861e;
        border-color: #53861e;
        color: white;
      }
    }
  }
}
.app-settings {
  .ant-tag {
    background-color: $primary-black;
    color: white;
    svg {
      color: white;
    }
    &:last-child {
      background-color: white !important;
      color: black;
    }
  }
}
.settings-update {
  button {
    background-color: $primary-blue;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    height: 28px;
    &:hover {
      background-color: #689439;
      border-color: #689439;
      color: white;
    }
    &:focus {
      background-color: #53861e;
      border-color: #53861e;
      color: white;
    }
  }
}
.display-charts-modal .ant-modal-mask {
  background-color: rgb(0 0 0 / 6%);
}
.report-v2-panel-annotation {
  background-color: white;
  padding: 20px;
  margin-bottom: 30px;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  min-height: 65px;
  .ant-spin-nested-loading {
    width: 100%;
  }
}
// REPORT COMMNETS
.report-annotation-wrapper {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  padding-right: 40px;
}
.report-annotation-single {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.ras-title {
  display: flex;
  align-items: center;
  h6 {
    font-size: 13px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  span {
    display: flex;
  }
  button {
    height: 25px;
    width: 25px;
    border: none;
    box-shadow: none;
    padding: 6px;
    background-color: $primary-black !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    svg {
      color: white;
    }
  }
}
.ras-reply {
  margin: 7px 0;
  button {
    border: none;
    box-shadow: none;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    height: auto;
    svg {
      margin-right: 5px;
    }
    &:focus,
    &:hover {
      color: black;
      border-color: transparent;
    }
  }
}
.ras-reply-wrapper {
  padding-left: 20px;
  padding-top: 10px;
  .reply-icon {
    margin-right: 3px;
    svg {
      color: #a7a7a7;
    }
  }
}
.report-annotation-buttons {
  position: absolute;
  right: 15px;
  top: 0;
  a,
  button {
    height: 25px;
    width: 25px;
    border: none;
    box-shadow: none;
    padding: 6px;
    background-color: #8dc441 !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: white;
    }
  }
  a {
    font-size: 14px;
    padding: 0;
    margin-bottom: 5px;
  }
}
.report-comment-settings-modal {
  .ant-modal-title {
    color: $secondary-color;
    text-transform: uppercase;
  }
  .ant-modal-body {
    font-size: 12px;
  }
  .ant-modal-footer {
    .ant-btn-default {
      background-color: gray;
      border-color: gray;
      color: white;
    }
  }
}
.main-breadcrumb {
  font-size: 12px;
  li {
    text-transform: uppercase;
    a {
      color: rgba(0, 0, 0, 0.85);
    }
    .ant-breadcrumb-separator {
      margin: 0 5px;
      color: rgba(0, 0, 0, 0.85);
    }
    &:last-child {
      a {
        color: gray !important;
        pointer-events: none;
      }
    }
  }
}
.header-drawer {
  display: flex;
  align-items: center;
  padding: 0 10px;
  button {
    border: none;
    box-shadow: none;
    background-color: transparent;
    height: 35px;
    width: 35px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 1.3em;
    }
  }
}
.header-apps-drawer {
  .ant-drawer-title {
    color: $primary-black;
    font-size: 22px;
  }
  .ant-drawer-body {
    padding: 25px 15px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      margin: 15px 0;
      a {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        color: $primary-black;
        &:hover {
          background-color: #f2f2f2;
        }
        span {
          margin-left: 10px;
        }
      }
      img {
        height: 40px;
        width: 50px;
        object-fit: contain;
      }
    }
  }
}
.ant-input-group .ant-input {
  font-size: 12px;
  font-weight: 400;
  &::placeholder {
    text-transform: capitalize;
  }
}
.datatable-content-editable {
  .ant-form-item-control-input {
    min-height: initial;
  }
}

// SSO DASHBOARD
.login-dashboard-title {
  h2 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: $secondary-color;
  }
}
.login-dashboard-accounts {
  margin-bottom: 30px;
}
.lda-single {
  background-color: white;
  a {
    display: block;
    padding: 10px 30px;
  }
  img {
    height: 200px;
    width: 100%;
    object-fit: contain;
  }
  h4 {
    text-align: center;
    font-size: 16px;
    min-height: 50px;
  }
}
.login-dashboard-reports {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 15px;
  }
  .ant-tabs-tab {
    padding: 10px 0;
  }
  .ant-tabs-tab-btn {
    padding: 3px 20px;
    background: #dfdfdf;
    border-radius: 30px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    background: $secondary-color;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-tab:hover {
    color: initial;
  }
}
// Multiple Date Component
.multiple-custom-date {
  display: flex;
  .Report-V2-filter-box-date {
    &:nth-child(1) {
      margin-right: 10px;
    }
  }
}
.renewal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 18px;
    color: #a9a9a9e3;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.renewal-popover {
  max-width: 400px;
  .ant-popover-inner {
    background-color: $primary-black;
    font-size: 12px;
  }
  .ant-popover-inner-content {
    color: white;
    ul {
      padding-left: 15px;
      li {
        list-style-type: disc;
      }
    }
  }
  .ant-popover-arrow-content {
    --antd-arrow-background-color: $primary-black;
  }
}
.ant-tooltip-inner {
  font-size: 12px;
}
// .specialist {
// 	min-width: 400px;
// }
.show_all_services,
.show_secondary_accounts {
  // background: transparent;
  // min-width: 0;
  // padding: 0;
  // box-shadow: none;
  // position: absolute;
  span {
    font-size: 11px;
    margin-left: 5px;
  }
  .ant-switch {
    min-width: 30px;
    height: 15px;
  }
  .ant-switch-checked {
    background: $primary-blue;
    max-width: 30px;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 14px - 0px);
  }
  .ant-switch-handle {
    top: 1px;
    width: 12px;
    height: 12px;
  }
}
// Freemium
.bulk-request-modal {
  .import-modals-form-buttons .ant-form-item-control-input-content {
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
  .url-form {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
        color: $primary-black;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
.bulk-history-modal {
  @extend .panel-body-content;
  .ant-modal-title {
    color: $primary-black;
  }
  .ant-modal-footer .ant-btn-default {
    background-color: gray;
    border-color: gray;
    color: white;
  }
}
.freemium-action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.freemium-switch {
  margin-left: 5px;
  height: 20px !important;
  .ant-switch-handle {
    width: 15px;
    height: 15px;
  }
}
.insights-workflow-modal {
  .ant-modal-title {
    color: $primary-black;
    text-transform: uppercase;
  }
  table {
    margin: 20px 0;
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.06);
    tr {
      border-bottom: 1px solid #f0f0f0;
      &:last-child {
        border-bottom: none;
      }
    }
    th {
      position: relative;
      color: #74787e;
      text-align: left !important;
      background: #fafafa;
      overflow-wrap: break-word;
      text-align: center;
      font-size: 13px;
      text-transform: capitalize;
      font-weight: 600;
    }
    td {
      font-size: 13px;
    }
    th,
    td {
      padding: 8px 5px;
      border-bottom: 1px solid #f0f0f0;
      transition: background 0.3s;
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
  }
}
.impact-modals {
  .ant-modal-title {
    text-transform: uppercase;
    color: $primary-black;
  }
}
.insights-workflow-text {
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      color: #aaaaaa;
    }
    a {
      text-decoration: underline;
    }
  }
}

// REBRANDING
.main-sidebar {
  z-index: 200;
  background-color: #22221e !important;
  .ant-menu {
    background-color: transparent;
    border: none;
    padding-top: 30px;
  }
  .ant-layout-sider {
    padding-top: 20px;
    background-color: #22221e !important;
    box-shadow: none;
  }
  .ant-layout-sider-collapsed {
    max-width: 110px !important;
    min-width: 110px !important;
    width: 110px !important;
    padding: 20px 10px;
  }
}
.sider-logo {
  display: flex;
  justify-content: center;
  img {
    height: 30px;
  }
}
.main-sidebar-footer {
  left: 0;
}
.ant-layout-header {
  box-shadow: none;
  background-color: #d7d7cc !important;
  transition: all 0.2s !important;
  padding-left: 130px;
}
.ant-layout-header.sider-length {
  margin: 0 !important;
  padding-left: 270px;
}
.header-logo {
  display: flex;
  align-items: center;
  padding-right: 0;
  img {
    width: initial;
    height: initial;
  }
  h2 {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
  }
}
.header-dropdown {
  padding-left: 50px;
}
.ant-layout-content {
  padding-left: 130px;
  background: #f0f0e6;
}
.ant-layout-content.sider-length {
  margin-left: 140px;
}
.header-right ul li {
  svg {
    color: #0e0e0b;
    font-size: 1.3em;
  }
  button {
    background-color: transparent;
    &:hover {
      background: transparent;
    }
  }
}
.header-notification svg {
  font-size: 1.5em !important;
}
.header-profile {
  .ant-btn {
    border: 1px solid black;
    border-radius: 50%;
    span {
      font-size: 12px;
      letter-spacing: 2px;
    }
    &:hover,
    &:focus {
      color: initial !important;
    }
  }
}
.panel-body-content {
  .ant-table-thead tr th,
  .bulk-history-modal .ant-table-thead tr th,
  .add-user-modal .ant-table-thead tr th,
  .history-modal .ant-table-thead tr th {
    background-color: #d7d7cc;
    color: #22221e;
    font-size: 14px;
    font-weight: 600;
  }
  tbody > tr:nth-of-type(odd) {
    background-color: transparent;
    // background-color: #176f3417;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 3px solid #f0f0e6 !important;
    a {
      text-decoration: underline;
      color: $primary-black;
      font-weight: 500;
    }
  }
  // .ant-table-tbody > tr {
  // 	&:last-child {
  // 		td {
  // 			border-bottom: none !important;
  // 		}
  // 	}
  // }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 12px 8px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #22221e;
    color: white;
    a,
    svg {
      color: white;
    }
    .ant-list-item {
      span {
        color: white;
      }
    }
    .contact-note {
      svg {
        color: white !important;
      }
    }
    .ant-switch-checked {
      background-color: #afafa7;
    }
    .impersonate-start-btn {
      background-color: white;
      border-color: white;
      color: $primary-black;
    }
    .account-notification-sub .ant-checkbox-checked .ant-checkbox-inner {
      background-color: white;
      border-color: white;
    }
  }
}

.panel-heading-title h2 {
  text-transform: capitalize;
  color: #000000;
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}
.ant-table td {
  font-size: 14px;
}
.report-notification-wrapper span {
  font-size: 12px;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  color: #878780;
  font-size: 20px !important;
}
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 15px;
}
.ant-layout-footer {
  margin-left: 110px;
}
.ant-layout-footer.sider-length {
  margin-left: 250px;
}
.main-sidebar-footer ul li a {
  color: #878780;
  font-size: 20px !important;
}
.panel-action-button {
  a,
  span {
    background: transparent;
    svg {
      color: $primary-black;
      font-size: 16px;
    }
  }
}
.ant-menu-root {
  .ant-menu-item > *,
  .ant-menu-submenu-title > * {
    font-size: 16px;
    color: #878780;
  }
}
.main-sidebar .ant-menu-light .ant-menu-item:hover,
.main-sidebar .ant-menu-light .ant-menu-item-active,
.main-sidebar
  .ant-menu-light
  .ant-menu:not(.ant-menu-inline)
  .ant-menu-submenu-open,
.main-sidebar .ant-menu-light .ant-menu-submenu-active,
.main-sidebar .ant-menu-light .ant-menu-submenu-title:hover {
  background-color: transparent !important;
}
.ant-menu-sub {
  padding: 0 !important;
}
.sider-toggle {
  // left: 300px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: -180px;
  top: 25px;
  z-index: 2000;
}
.account-details-info ul li:nth-child(odd) {
  background: rgb(23 111 52 / 15%);
}
.panel-main {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../Assets/Images/graybg.png");
    background-position: right;
    background-size: cover;
  }
}
.panel-body-content .ant-pagination-item-active,
.bulk-history-modal .ant-pagination-item-active,
.add-user-modal .ant-pagination-item-active,
.history-modal .ant-pagination-item-active {
  background: $primary-black;
  border-color: $primary-black;
}
.panel-body-content .ant-table table,
.bulk-history-modal .ant-table table,
.add-user-modal .ant-table table,
.history-modal .ant-table table {
  border-bottom: none;
}
.add-user-modal {
  .ant-input-group .ant-input {
    height: 32px;
  }
}
.report-annotation-buttons a,
.report-annotation-buttons button {
  background-color: $primary-black !important;
}
.report-v2-filter-buttons .ant-switch-checked {
  background-color: $primary-black;
}
.report-v2-filter-buttons button {
  color: $primary-black;
  &:focus {
    color: $primary-black;
  }
}
.user-details-table {
  border-bottom: none;
  tr:nth-of-type(odd) {
    background-color: #d7d7cc;
  }
}
// .agitalbg{
// 	background-image: url("../Assets/Images/graybg.png") !important;
// 	background-position: center !important;
// 	background-size: cover !important;
// }
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #d7d7cc;
}
.panel-body-select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.panel-body-select .ant-input-affix-wrapper,
.panel-body-select .ant-btn-icon-only {
  border: none !important;
}

.panel-heading-buttons button {
  background-color: white;
  color: $primary-black;
  border-color: $primary-black;
  &:hover {
    background-color: $primary-black;
    border-color: $primary-black;
    color: white;
  }
  &:focus {
    background-color: white;
    color: $primary-black;
    border-color: $primary-black;
  }
}
.toggle-select-dropdown {
  position: relative;
  padding-top: 40px;
}
.toggle-handler-wrapper {
  display: flex;
}
.toggle-handler {
  position: absolute;
  top: 0;
  padding: 5px 12px;
  font-size: 12px;
  width: 100%;
  button {
    margin-left: 10px;
  }
}

.select_filter {
  .Report-V2-filter-option-title {
    margin-bottom: 0;
  }
  .toggle-handler-upper {
    font-size: 10px;
    button {
      margin-right: 5px;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    .ant-form-item-control-input {
      min-height: initial !important;
    }
  }
  .ant-switch-small {
    min-width: 26px;
    height: 14px;
    line-height: 14px;
  }
  .ant-switch-small .ant-switch-handle {
    width: 10px;
    height: 10px;
  }
}
$counter: 0;
.ras-comment {
  list-style: none;
  padding: 0;
  counter-reset: item $counter;
  ul,
  ol {
    margin-bottom: 0;
  }
  ol {
    li {
      &::before {
        counter-increment: item;
        content: counter(item) ". ";
        display: inline-block;
        text-align: right;
        margin-right: 2px;
      }
    }
  }
  ul {
    li {
      padding-left: 7px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 3px;
        width: 3px;
        background: black;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
}
.top-right-text {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  text-align: right;
  h4 {
    font-weight: 600;
    margin-bottom: 0;
  }
}
span.mention {
  margin-right: 5px !important;
  font-weight: 600;
}
#quill-mention-list {
  margin-top: 5px;
  padding: 5px 10px;
  box-shadow: 0px 0px 4px 0px #2c2c2c17;
}
.ql-mention-list-item:hover {
  cursor: pointer;
  color: black;
}
.ad-disabled,
.as-passive span {
  background: transparent !important;
  svg {
    color: #d7d7cc !important;
  }
}
.account-details-info ul li:nth-child(odd) {
  background: #d7d7cc;
}
.account-details-buttons ul li a {
  color: $primary-black;
  text-decoration: underline;
  &:hover {
    color: $primary-gray;
  }
}
.ant-upload.ant-upload-select-picture-card {
  border-radius: 0 !important;
}
.profile-modal-title {
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 24px;
    font-weight: 600;
  }
}
.profile-modal .ant-modal-footer .ant-btn-default {
  background-color: $primary-black;
  border-color: $primary-black;
}
.ant-tooltip-inner {
  background-color: black;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(
    to right bottom,
    rgba(0, 0, 0),
    rgb(0 0 0)
  );
}
.new-role {
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-input {
    font-size: 12px;
  }
  .ant-form-item-control-input-content {
    font-size: 12px;
  }
}
.new-role-save {
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $primary-gray;
      border-color: $primary-gray;
      &:hover,
      &:focus {
        background-color: $primary-black;
        border-color: $primary-black;
      }
    }
  }
}
.notes-archieve-modal {
  .ant-modal-title {
    color: $primary-black;
    font-size: 22px;
  }
  .ant-modal-footer {
    .ant-btn {
      background-color: $primary-black;
      border-color: $primary-black;
      color: white;
      &:hover {
        background-color: $primary-gray;
      }
      &:focus {
        background-color: $primary-black;
      }
    }
  }
}
.amazon-rating-wrapper {
  display: flex;
}
.one_star_pc {
  background-color: #fd8a8a;
}
.two_star_pc {
  background-color: #ffcc80;
}
.three_star_pc {
  background-color: #ffff83;
}
.four_star_pc {
  background-color: #b3efb6;
}
.five_star_pc {
  background-color: #7cd081;
}
.rating-single {
  padding: 6px 8px;
  min-width: 35px;
  font-size: 10px;
  font-weight: 600;
}
.top-right-insights {
  text-align: right;
  font-weight: 600;
  // margin-bottom: 10px;
  font-size: 13px;
}

.top-right-insights--holistic {
  text-align: right;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 13px;
}
.api-units {
  margin-bottom: 0 !important;
  border-left-color: $primary-black !important;
}
.header-dropdown-menu {
  position: fixed;
}
.input-dropdown-menu {
  z-index: 99;
}
.upper-links {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  a {
    color: #0e0e0b;
    text-decoration: underline;
    &:last-child {
      margin-left: 5px;
    }
  }
}
.ant-table-row-expand-icon {
  &:hover {
    background-color: $primary-black !important;
    border-color: $primary-black !important;
  }
  &:focus {
    background-color: #fff !important;
    border-color: #f0f0f0 !important;
    color: inherit !important;
  }
}

.mobile-filter-btn {
  display: none;
}
.panel-body-content .ant-table-tbody > tr.ant-table-row:hover > td,
.history-modal .ant-table-tbody > tr.ant-table-row:hover > td,
.add-user-modal .ant-table-tbody > tr.ant-table-row:hover > td,
.bulk-history-modal .ant-table-tbody > tr.ant-table-row:hover > td,
.panel-body-content .ant-table-tbody > tr > td.ant-table-cell-row-hover,
.history-modal .ant-table-tbody > tr > td.ant-table-cell-row-hover,
.add-user-modal .ant-table-tbody > tr > td.ant-table-cell-row-hover,
.bulk-history-modal .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #22221e !important;
}

// CLV GOAL SETTINGS
.gst-table {
  tr {
    td {
      &:not(:first-child) {
        text-align: center;
      }
    }
  }
}
.goal-setting-single {
  margin-bottom: 20px;
  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    font-size: 13px;
    margin-bottom: 20px;
  }
}
.gss-caption-wrapper {
  display: flex;
  align-items: center;
}
.gss-caption {
  font-size: 12px;
  text-align: right;
  width: 70%;
}
.gss-input {
  input {
    text-align: center;
  }
  .ant-input-number-out-of-range input {
    color: rgba(0, 0, 0, 0.85);
  }
}
.gss-percent {
  .ant-input-number-affix-wrapper {
    flex-direction: row-reverse;
  }
  h2 {
    margin-bottom: 5px;
  }
  p {
    max-width: 415px;
  }
}
.gss-action-btns {
  @extend .revenue_action_buttons;
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}
.gst-table {
  tr.ant-table-placeholder {
    td {
      padding: 10px 5px !important;
      color: #0e0e0b;
      font-size: 14px !important;
    }
  }
  th {
    background-color: #0e0e0b !important;
    color: white !important;
    &:not(:last-child) {
      border-right: 1px solid #ffffff94 !important;
    }
  }
}

// INSIGHTS EMAIL LAYOUT
.insights-notification-wrapper {
  background: #f0f0e6;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  .insights-report {
    color: #0e0e0b;
    &:hover {
      color: #22221e;
    }
  }
}

.token-main {
  z-index: 2000;
}
.login-right {
  background-color: #fffef4 !important;
  h2 {
    text-align: center;
    color: $primary-black;
  }
}

// Import spend modal
.import-modals-spend {
  .ant-form-item-label > label {
    min-width: 180px;
  }
}

.remove-tier-btn {
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;

  & svg {
    font-size: 16px;
    color: #da0909 !important;
  }

  &:hover {
    border: none;
    outline: none;
  }
}
// BC-2281
.upload-product-modal {
  .ant-form-item {
    margin-bottom: 20px;
  }
  .import-modals-form-buttons {
    margin-bottom: 0;
  }
  .ant-select.ant-select-in-form-item {
    width: 100%;
  }
  #upload_catalog_url_frequency {
    label {
      display: block;
    }
  }
  .ant-tabs-tab {
    padding-top: 0;
  }
  .ant-form-extra {
    padding-left: 180px;
    .ant-select-selection-placeholder {
      font-size: 12px;
    }
    p {
      margin-bottom: 10px;
    }
    .ant-select {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    .extra-create {
      button {
        background-color: $primary-black;
        border-color: $primary-black;
        color: white;
      }
    }
    .extra-cancel {
      button {
        background-color: white;
        border-color: $primary-black;
        color: $primary-black;
        margin-left: 15px;
      }
    }
    .create-disabled {
      cursor: not-allowed;
      button {
        pointer-events: none;
        background: #c1c1c1;
        border-color: #c1c1c1;
      }
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-black;
  }
  .ant-radio-inner::after {
    background-color: $primary-black;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $primary-black;
    background-color: $primary-black;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary-black;
  }
  .ant-tabs-ink-bar {
    background-color: $primary-black;
  }
}
.create-frequency-time {
  .ant-picker-footer {
    display: none;
  }
  .ant-picker-time-panel-column::after {
    height: auto;
  }
}
