.trends-datatable {
	margin-top: 20px;
	table {
		width: 100%;
		text-align: left;
		border-radius: 2px 2px 0 0;
		border-collapse: separate;
		border-spacing: 0;

		thead {
			display: table-header-group;
			vertical-align: middle;
			border-color: inherit;

			th {
				position: relative;
				color: #000000d9;
				font-weight: 500;
				text-align: left;
				background: #fafafa;
				border-top: 1px solid rgba(0, 0, 0, 0.06);
				border-bottom: 1px solid rgba(0, 0, 0, 0.06);
				transition: background 0.3s ease;
				padding: 8px;
				overflow-wrap: break-word;
				text-align: center;
				&:first-child {
					background: transparent !important;
					border: none !important;
					border-right: 1px solid rgba(0, 0, 0, 0.06) !important;
				}
				&:last-child {
					border-right: 1px solid rgba(0, 0, 0, 0.06) !important;
				}
			}
		}
		td {
			display: table-cell;
			vertical-align: inherit;
			text-align: center;
			&:first-child {
				font-weight: 600;
				text-align: right !important;
				padding-right: 30px;
				width: 200px;
				border-bottom: none !important;
				border-right: 1px solid #e6e6e6;
				border-top: none !important;
				border-left: none !important;
			}
		}
		td:first-child,
		th:first-child {
			border-left: 1px solid rgba(0, 0, 0, 0.06);
			text-align: left;
		}
		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
		}
		tbody {
			display: table-row-group;
			vertical-align: middle;
			border-color: inherit;
			tr:last-child {
				td {
					border-bottom: 1px solid rgba(0, 0, 0, 0.06);
				}
			}
			td {
				transition: background 0.3s;
				position: relative;
				padding: 8px;
				overflow-wrap: break-word;
				&:first-child {
					position: relative;
					span {
						position: absolute;
						right: 13px;
						top: 10px;
						svg {
							margin-right: 0;
						}
					}
				}
				&:last-child {
					border-right: 1px solid rgba(0, 0, 0, 0.06);
				}
				&:nth-child(6) {
					border-left: 1px solid rgba(0, 0, 0, 0.06);
				}
			}
		}
	}
	svg {
		// color: "#16ce16";
		margin-right: 10px;
	}

	&--noborder {
		table {
			tbody {
				td {
					&:nth-child(6) {
							border-left: none !important;
						}
				}
			}
		}
	}
}

.switch-parent-trend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.switch-child-text {
	font-size: 12px;
}
.switch-child-button {
	    margin-left: 5px;
}