.device_column {
	span {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		svg {
			float: right;
		}
	}
}
.device_column_green {
	svg {
		fill: #50ae42;
		stroke: #50ae42;
	}
}
.device_column_light_blue {
	svg {
		fill: #4cbaf8;
		stroke: #4cbaf8;
	}
}
.device_column_blue {
	svg {
		fill: #3272b5;
		stroke: #3272b5;
	}
}

.performance-datatable {
	margin-top: 20px;
	table {
		width: 100%;
		text-align: left;
		border-radius: 2px 2px 0 0;
		border-collapse: separate;
		border-spacing: 0;
		border: 1px solid rgba(0, 0, 0, 0.06);
		thead {
			display: table-header-group;
			vertical-align: middle;
			border-color: inherit;
			th {
				position: relative;
				color: #000000d9;
				font-weight: 500;
				text-align: left;
				background: #fafafa;
				transition: background 0.3s ease;
				padding: 8px;
				overflow-wrap: break-word;
				text-align: center;
				font-size: 11px;
			}
		}
		td {
			display: table-cell;
			vertical-align: inherit;

			text-align: center;
			font-size: 12px;

			&:nth-child(odd) {
				border-right: 1px solid rgba(0, 0, 0, 0.06);
			}
			&:last-child {
				border-right: none;
			}
		}
		td:first-child,
		th:first-child {
			text-align: left;
			min-width: 110px;
			max-width: 140px;
			padding-right: 15px;
		}
		thead tr {
			text-transform: uppercase;
		}
		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
		}
		tbody {
			display: table-row-group;
			vertical-align: middle;
			border-color: inherit;
			td {
				transition: background 0.3s;
				position: relative;
				padding: 8px;
				overflow-wrap: break-word;
				// border-right: 1px solid rgba(0, 0, 0, 0.06);
			}
		}
		tfoot {
			background: #fafafa;
			td {
				padding: 8px;
			}
		}
	}
	svg {
		margin-right: 5px;
	}
}
.performance-report-color-icon {
	span {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		display: inline-flex;
	}
}
.campaign-performance-table td.ant-table-cell:nth-child(1) {
    position: relative;
	span{
		display: block;
		position: relative;
		padding-left: 25px;
	svg{
		position: absolute;
		right: 0;
		margin: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	}
	button{
		position: absolute;
    	z-index: 2;
		&:hover, &:focus{
			background-color: initial;
			border-color: gray;
			color: black;
		}
	}
}