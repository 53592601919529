.header-filter-icon {
  display: none;
}
.table-header-buttons,
.ant-popover {
  &:hover {
    .header-filter-icon {
      display: inline-block;
    }
  }
}
.datatable-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-bottom: 15px;
  .ant-tabs-nav{
    margin-bottom: 0;
  }
}
.datatable-toggle-info {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.datatable-toggle {
  margin-right: 15px;
  font-size: 12px;
  button {
    margin-left: 5px;
  }
}
.datatable-search {
  position: absolute;
  max-width: 250px;
  left: 0;
  input {
    height: 32px;
  }
  button {
    background-color: #0E0E0B;
    border-color: #0E0E0B;
    padding: 4px 10px !important;
    font-size: 12px;
  }
}
.datatable-calc {
  position: absolute;
  top: -53px;
  right: -20px;
  display: inline-block;
  font-weight: 600;
  svg{
    color: #0E0E0B !important;
  }
}
.export-button-wrapper {
  margin-bottom: 0 !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right)
  > .ant-table-container::after,
.ant-table-ping-left:not(.ant-table-has-fix-left)
  > .ant-table-container::before {
  box-shadow: none;
}
.blue-column {
  background-color: #a8d1f3 !important;
}
.green-column {
  background-color: #c9f1cb !important;
}
.yellow-column {
  background-color: #ffcc80 !important;
}
.red-column {
  background-color: #f39e8d !important;
}
.orange-column{
  background-color: #ffc000 !important;
}
.dark-green-column {
  background-color: #92d050 !important;
}
.light-green-column {
  background-color: #b3efb6 !important;
}
.light-red-column {
  background-color: #fd8a8a !important;
}
.light-orange-column {
  background-color: #FFA500 !important;
}
.light-yellow-column {
  background-color: #ffff83 !important;
}
.ant-table {
  th {
    font-size: 11px;
  }
  td {
    font-size: 12px;
  }
}
td.ant-table-column-sort {
  background: transparent !important;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #ffffff;
}
.ant-table-pagination.ant-pagination {
  margin: 10px 0;
}
.datatable-content {
  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px whitesmoke;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d5d5;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s linear;
  }
  .ant-table-column-sorter{
    color: #737373;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    text-align: center;
    &:first-child {
      text-align: left !important;
    }
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fafafa;
  }
}
.ant-switch-checked {
  background: #0E0E0B;
    &:hover{
      background-color: #22221E !important;
    }
    &:focus{
      background-color: #0E0E0B !important;
    }
}
.semrush-action-buttons {
  margin-top: -30px;
  z-index: 20;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 650px;
  a,
  button {
    margin-right: 4px;
    background-color: #0E0E0B;
    border-color: #0E0E0B;
    &:hover{
      background-color: #22221E !important;
      border-color: #22221E !important;
      color: white;
    }
    &:focus{
      background-color: #0E0E0B !important;
      border-color: #0E0E0B !important;
      color: white;
    }
  }
  a {
    color: white;
    display: inline-block;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    line-height: 1.5715;
    &:hover{
      background-color: #22221E;
      border-color: #22221E;
      color: white;
    }
    &:focus{
      background-color: #0E0E0B;
      border-color: #0E0E0B;
      color: white;
    }
  }
}
.ant-table-filter-dropdown {
  min-width: 0px !important;
}
.datatable-filter {
  padding: 8px;
  button {
    border: none;
  }
}
.datatable-filter-modal,
.datatable-toggle-modal {
  .ant-modal-title {
    text-transform: uppercase;
    color: #0E0E0B;
    font-size: 1.5em;
  }
  .ant-modal-footer{
    button{
          background-color: #0e0e0b;
          border-color: #0e0e0b;
          color: #fff;
    }
  }
}
.datatable-toggle-modal .ant-modal-body {
  padding: 5px 20px;
}
.filter-form-options {
  display: flex;
  .ant-row {
    width: 33%;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  .ant-form-item-label {
    padding-bottom: 5px;
    font-weight: 600;
  }
}
.filter-form-buttons {
  display: flex;
  justify-content: flex-end;
  .ant-row {
    margin-bottom: 0;
  }
  button {
    background-color: #0e0e0b;
    border-color: #0e0e0b;
    margin-left: 15px;
      &:hover {
        background-color: #22221e;
        border-color: #22221e;
        color: white;
      }
  }
}
.toggle-form-options {
  height: 350px;
  overflow-y: scroll;
  .ant-list-item {
    padding: 8px 0;
    text-transform: capitalize;
    border-bottom: none;
    span {
      font-size: 12px;
    }
  }
}
.lpp-all {
  padding-left: 0 !important;
  border-left: none !important;
}
.ant-table-selection-column {
  padding-right: 0 !important;
  border-right: none !important;
}
// Datatable header color
th.header-yellow-column {
  background-color: #ffcc80 !important;
}
th.header-green-column {
  background-color: #c9f1cb !important;
}
th.header-blue-column {
  background-color: #a8d1f3 !important;
}
th.header-red-column {
  background-color: #f39e8d !important;
}
th.header-gray-column {
  background-color: #d9d9d9 !important;
}

.dt-left {
  text-align: left !important;
}
.dt-center {
  text-align: center !important;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #12a3d8;
}
.ant-table-cell-with-append {
  display: flex;
  min-width: 250px;
  strong {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 20px;
  }
}
.action-dropdown {
  background-color: #8dc441;
  color: white;
  border-color: #8dc441;
  height: initial;
  font-size: 12px;
  padding: 2px 8px;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:hover {
    background-color: #689439;
    border-color: #689439;
    color: white;
  }
  &:focus {
    background-color: #53861e;
    border-color: #53861e;
    color: white;
  }
}
.action-dropdown-wrapper {
  li {
    padding: 2px 10px;
  }
  .ant-dropdown-menu-title-content {
    font-size: 0.9em;
    text-transform: capitalize;
  }
}
.table-header-tooltip {
  margin-left: 10px;
  color: #0E0E0B !important;
}
.table-tooltip-modal {
  .ant-modal-title {
    text-transform: uppercase;
    color: #0E0E0B;
    font-size: 20px;
  }
  .ant-modal-body {
    font-size: 13px;
    padding: 30px;
    ul {
      padding-left: 30px;
      li {
        list-style-type: square;
      }
    }
  }
  .ant-modal-footer {
    button {
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      background-color: #0e0e0b;
      border-color: #0e0e0b;
      &:hover {
        background-color: #22221e;
        border-color: #22221e;
        color: white;
      }
    }
  }
}
.arrow-column-wrapper {
  display: flex;
  justify-content: center;
  span {
    min-width: 55px;
  }
}
// .datatable-content .ant-table-thead > tr > th{
//   .ant-table-column-sorter, .ant-table-filter-trigger{
//     display: none;
//   }
//   &:hover{
//      .ant-table-column-sorter, .ant-table-filter-trigger{
//       display: inline-flex;
//     }
//   }
// }
.fixed-footer{
  position: sticky !important;
  left: 0;
  z-index: 2;
}