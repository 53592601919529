.insights {
	padding-top: 20px;
	h4 {
		font-size: 12px;
		display: inline-block;
		background: #0E0E0B;
		color: white;
		padding: 2px 8px;
		border-radius: 50px;
	}
	ul {
		li {
			margin: 5px 0;
			padding-left: 10px;
			position: relative;
			font-size: 13px;
			&::before {
				content: "-";
				position: absolute;
				left: 0%;
			}
		}
	}
}
