@media only screen and (max-width: 767px) {
.main-sidebar{
    position: absolute;
    left: -250px;
    margin-top: 120px;
     transition: all 0.6s !important;
        // .ant-layout-sider-collapsed {
        // max-width: 110px !important;
        // min-width: 110px !important;
        // width: 110px !important;
        // padding: 20px 10px;
        // }
}
.mobile-menu-adjust{
    position: relative;
    left: 0;
    
}
.ant-layout-header{
    height: auto !important;
    padding: 0 !important;
    padding-left: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-header-row {
    display: flex;
    align-items: flex-end;
    min-height: 120px;
}
.header-left{
    display: block !important;
}
.header-logo{
    justify-content: center;
    img {
    width: 35px !important;
    height: 20px !important;
    }
    h2{
        font-size: 14px;
        line-height: 20px;
    }
}
.header-dropdown{
    margin-top: 10px;
    padding-left: 0 !important;
    line-height: initial;
    display: flex;
    justify-content: center;
    .ant-select{
        min-width: 175px !important; 
        max-width: 175px !important;
        &:first-child{
            margin-right: 5px !important;
        }
    }
}
.header-right{
    justify-content: center !important;
    padding-bottom: 15px;
    ul{
        padding-left: 50px;
    }
}
.ant-layout-content{
    padding: 15px !important;
    padding-left: 15px !important;
    margin-top: 122px !important;
}
.ant-layout-footer{
    margin-left: 0 !important;
    padding-left: 20px !important;
}
.mobile-filter-btn {
    display: block !important;
}
.report-v2-filter-buttons-wrapper{
    justify-content: flex-start !important;
}
.Report-V2-filter-option{
    width: 100%;
}
.Report-V2-filter-box-autocomplete, .Report-V2-filter-box-date{
    max-width: 100% !important;
}
.ant-drawer-content-wrapper{
    width: 200px !important;
}
.header-apps-drawer ul{
    display: block !important;
    li{
        width: 100% !important;
    }
}
.panel-body-content {
    .ant-table-container {
        max-width: 100%;
        overflow: auto;
    }
    .ant-table-thead tr th,
	.bulk-history-modal .ant-table-thead tr th,
	.add-user-modal .ant-table-thead tr th,
	.history-modal .ant-table-thead tr th {
		font-size: 12px !important;
	}
    .ant-table-thead > tr > th,
	.ant-table-tbody > tr > td,
	.ant-table tfoot > tr > th,
	.ant-table tfoot > tr > td {
		padding: 10px 5px !important;
	}
    .ant-switch {
		min-width: 35px !important;
		height: 15px !important;
	}
	.ant-switch-checked .ant-switch-handle {
		left: calc(100% - 14px - 0px) !important;
	}
	.ant-switch-handle {
		width: 10px !important;
		height: 10px !important;
	}
}
.ant-table td {
    font-size: 12px !important;
}
.panel-body-select{
    .ant-input-search{
        width: 180px !important;
    }
}
.panel-heading-buttons button{
    font-size: 12px !important;
    padding: 5px 10px !important;
}
.panel-heading-title{
    margin-bottom: 10px;
}
.account-details-phb{
    flex-wrap: wrap;
    justify-content: flex-start !important;
    button{
        padding: 5px 8px !important;
       font-size: 12px !important;
       margin: 3px !important;
    }
    a{
        margin-right: 3px !important;
    }
}
.account-details-info {
    margin-right: 0 !important;
}
.account-details-buttons {
    margin-top: 20px;
    span{
        text-align: left !important;
    }
    ul{
        li{
            text-align: left !important;
        }
    }
}
.sider-length {
    margin-left: 0 !important;
}
.sider-toggle{
    right: initial !important;
    left: 23% !important;
    top: 81px !important;
    position: fixed !important;
    svg{
        font-size: 17px;
    }
}
.show-mobile-filter{
	display: block;
}
.hide-mobile-filter{
	display: none;
}
.pbc-upper{
    display: block !important;
    button{
        padding: 5px 10px !important;
    }
}
.user-update-single{
    border: none !important;
    padding: 0 !important;
}
.account-advanced-setting-form-wrapper{
    padding: 10px !important;
}
.currency-single {
    padding: 0 !important;
}
#advanced-settings_budget_goals_type {
    display: block !important;
}
.budget_goals_combined_services-wrapper{
    margin-left: 0 !important;
}
.main-breadcrumb {
    font-size: 10px !important;
}
.panel-heading-title h2{
    font-size: 24px !important;
    line-height: 30px !important;
}
.import-modals .ant-modal-title{
    font-size: 18px !important;
}
.import-modals-form-buttons {
    display: block !important;
    .ant-form-item-control-input-content{
        display: block !important;
        button, a{
            width: 100%;
            padding: 5px 10px !important;
            margin-bottom: 5px !important;
            justify-content: center;
        }
    }
}
.report-v2-panel-body{
    padding: 10px !important;
}
.key-metrics-wrapper{
    justify-content: center !important;
    flex-wrap: wrap;
}
.key-metrics-circle{
    width: 160px;
    height: 160px;
    border-width:3px;
    box-shadow: none !important;
    h5{
        font-size: 13px !important;
        max-width: 90px !important;
    }
    h2{
        font-size: 20px;
    }
}
.performance-datatable, .table-slider{
    overflow-y: scroll;
}

.slider-single-caption{
    width: initial !important;
    margin-bottom: 5px;
}
.lift-dropdown{
    padding: 0 !important;
    border-left: none !important;
    flex-wrap: wrap;
    justify-content: space-between;
}
.lift-single-dropdown{
    margin-right: 0 !important;
    margin: 5px 0;
    .lift-span{
        min-width: 75px;
        display: inline-block;
    }
}
.lift-single-buttons button{
    font-size: 11px !important;
    padding: 5px 10px;
    height: auto;
}
.lift-wrapper{
    left: 5px !important;
}
.mmm-main-table {
    overflow-y: scroll;
}
.insights-wrapper p{
    flex-wrap: wrap;
}
.insights-variance{
    display: block !important;
}
.rsquare-insights, .accuracy-insights{
    padding: 5px 0 3px;
}
.semrush-action-buttons{
    display: block !important;
    margin-top: 0 !important;
    a{
        margin: 5px 0;
    }
}
.header-notifications-wrapper{
    min-width: initial !important;
}
.main-sidebar-footer{
    bottom: 120px !important;
}
.rcb-right {
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    .ant-select{
        width: 100% !important;
    }
}
.mec-total ul{
    display: block !important;
}
.mec-single-list ul{
    padding: 50px !important;
}
.mec-total ul{
     li{
        padding: 10px 0 10px 20px;
        h5, span{
            width: initial !important;
        }
    }   
 }
 .service-setting-form .ssf-6{
    display: inline-table !important;
 } 
 .default-form-single .ant-form-item-control-input-content span{
    position: initial !important;
 }
 .service-setting-form .ant-modal-title{
    font-size: 17px !important;
 } 
 .service-fee-readonly::before{
    width: calc(100% + 40px) !important;
 }
}