.gads-wrapper {
	background: #f0f2f5;
}
.gads-bg {
	position: relative;
}
.gads-bg-wallpaper {
	img {
		width: 100%;
		height: 300px;
	}
}
.gads-bg-logo {
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);
}
.gads-content-wrapper {
	display: flex;
	justify-content: center;
	margin-top: -170px;
}
.gads-content {
	width: 70%;
	position: relative;
	min-width: 1100px;
	strong {
		font-weight: 600;
	}
}
.gads-content-single {
	padding: 38px 42px;
	background: white;
	box-shadow: 0px 0px 24px 0px #00000004;
	margin-bottom: 20px;
	.red_highlight {
		color: red;
		font-weight: 600;
	}
	.green_highlight {
		color: #60a512;
		font-weight: 600;
	}
}
.gads-header {
	.gads-imporve-btn {
		display: flex;
		justify-content: center;
	}
}
.gads-header-title {
	border-bottom: 2px solid #f0f0e6;
	h4 {
		font-size: 20px;
		font-weight: 300;
		line-height: 28px;
		letter-spacing: 0em;
		text-align: center;
	}
	.gads-header-title-h2 {
		font-size: 52px;
		font-weight: 900;
		line-height: 56px;
		letter-spacing: 0em;
		text-align: center;
		color: #0e0e0b;
		margin-bottom: 0.5em;
	}
}
.gads-header-score {
	display: flex;
	justify-content: center;
	padding: 0 60px;
	margin: 20px 0;
}
.gadshs-single {
	padding: 24px;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	h6 {
		font-size: 20px;
		font-weight: 300;
		line-height: 28px;
		letter-spacing: 0em;
		text-align: center;
	}
	h4 {
		font-size: 28px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 0em;
		text-align: center;
		text-transform: uppercase;
	}
	.score {
		height: 140px;
		width: 140px;
		border-radius: 50%;
		border: 1px solid #d7d7cc;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.gads-header-title-h2 {
		font-size: 36px;
		font-weight: 900;
		line-height: 44px;
		letter-spacing: 0em;
		text-align: center;
		display: inline-block;
		margin-bottom: 0;
	}
}
.gads-header-info {
	p {
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: center;
	}
}
.gads-imporve-btn {
	margin-top: 30px;
	button {
		padding: 12px 24px;
		background-color: #974cd9;
		height: initial;
		color: white;
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0em;
		&:hover {
			background-color: #6c28a8;
			border-color: #6c28a8;
			color: white;
		}
	}
}
.gads-waste,
.gads-share {
	h6 {
		font-size: 16px;
		font-weight: 900;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
	}
	h4 {
		font-size: 32px;
		font-weight: 300;
		line-height: 40px;
		letter-spacing: 0em;
		text-align: left;
	}
	h2 {
		background-color: #0e0e0b;
		color: white;
		padding: 12px 24px;
		font-size: 28px;
		font-weight: 900;
		line-height: 36px;
		letter-spacing: 0em;
		text-align: center;
	}
	.ant-progress-inner {
		border-radius: 0;
		background-color: #f0f0e6 !important;
	}
	.ant-progress-success-bg,
	.ant-progress-bg {
		background-color: #974cd9;
		height: 42px !important;
		border-radius: 0;
	}
	p {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}
.progress-text {
	display: block;
	padding-bottom: 30px;
	margin-bottom: 20px;
	border-bottom: 3px solid #f0f0e6;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 5px;
}
.share-pt {
	margin-bottom: 0;
	border-bottom: 0;
}
.gads-share-progres {
	display: flex;
	h2 {
		margin-bottom: 0;
		padding: 3px 6px;
		min-width: 75px;
	}
}
.gcs-title {
	margin-bottom: 24px;
	h4 {
		font-size: 28px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 0em;
		text-align: left;
	}
}
.gpc-single {
	padding: 12px;
	border-top: 4px solid #60a512;
	margin: 20px 10px;
	h4 {
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		color: #0e0e0b;
	}
	h2 {
		font-size: 24px !important;
		font-weight: 900;
		line-height: 32px;
		letter-spacing: 0em;
		text-align: left;
		color: #0e0e0b;
	}
}
.gads-performance{
	.gads-imporve-btn {
    	margin-top: 20px;
	}
}
.gads-performance-text {
	p {
		font-size: 14px;
		font-weight: 300;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		color: #0e0e0b;
		span{
			line-height: 2.5;
		}
	}
}
.gcs-single {
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-bottom: 3px solid #f0f0e6;
}
.gcs-title-btn {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	align-items: center;
	h4 {
		font-size: 20px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0em;
		text-align: left;
	}
	span {
		float: right;
		background-color: #a0c711;
		padding: 8px;
	}
}
.gsctb-first {
	justify-content: flex-end !important;
}
.gsc-text {
	margin-top: 20px;
	font-size: 14px;
	p {
		font-weight: 300;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
	}
}

.gsc-table {
	table {
		width: 100%;
		th,
		td {
			padding: 8px;
		}
	}
	thead {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: left;
	}
	tbody {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		tr {
			&:nth-child(odd) {
				background-color: #f0f0e6;
			}
		}
		td {
			span {
				display: flex;
				align-items: center;
				svg {
					margin-right: 5px;
				}
			}
			&:not(:first-child){
				font-size: 12px;
			}
		}
	}
	tfoot {
		background-color: #181814;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		color: #ffffff;
		text-transform: uppercase;
		td {
			&:not(:first-child){
				font-size: 12px;
			}
		}
	}
}
.gsc-charts-wrapper {
    display: flex;
    justify-content: center;
}
.gcs-charts {
	margin-bottom: 30px;
	h4 {
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
	}
	.ant-row {
		&:nth-child(1) {
			margin-bottom: 30px;
		}
	}
	.ant-col {
		&:not(:last-child) {
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 2px;
				height: 100%;
				background-color: #f0f0e6;
			}
		}
	}
}
.gads-campaign,
.gads-device {
	table {
		thead {
			th {
				font-weight: 400;
			}
		}
		tbody {
			font-size: 12px;
			font-weight: 700;
		}
		tfoot {
			font-size: 12px;
		}
	}
}
.gcs-device-table {
	h4 {
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: 0em;
		text-align: left;
		margin-bottom: 24px;
	}
	table {
		width: 100%;
		th,
		td {
			padding: 8px;
		}
	}
	thead {
		th {
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: left;
		}
	}
	tbody {
		font-size: 12px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		tr {
			&:nth-child(even) {
				background-color: #dbe610;
			}
		}
		td {
			span {
				display: flex;
				align-items: center;
				svg {
					margin-right: 5px;
				}
			}
		}
	}
}
.gads-download {
	padding: 32px 0;
	button {
		border: none;
		border-radius: 0;
		height: 48px;
		padding: 12px 24px;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		box-shadow: 0px 0px 16px 0px #0000000f;
	}
}
.gads-privacy {
	margin-bottom: 30px;
	p {
		font-size: 14px;
		font-weight: 300;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
	}
}
.gads-footer {
	background-color: #0e0e0b;
	padding: 60px;
	p {
		color: #d7d7cc;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: center;
		padding: 3px 0;
		a {
			font-weight: 700;
			color: #ffffff;
		}
	}
}
// GADS REDIRECT PAGE
.gads-signup-wrapper {
	position: relative;
	z-index: 2;
	padding: 60px 100px;
	height: 100%;
	&::before {
		content: "";
		position: absolute;
		background-image: url("../../Assets/Images/agbg.png");
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		z-index: -1;
		left: 0;
		top: 0;
	}
}
.gadssw-single {
	padding: 50px 80px;
	background: #fffef4;
	box-shadow: 0px 0px 16px 0px #00000033;
	color: #0e0e0b;
}
.gadssw-upper {
	margin-bottom: 40px;
	text-align: center;
	h2 {
		font-size: 32px;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: 0em;
	}
	p {
		font-size: 20px;
		font-weight: 300;
		line-height: 28px;
		letter-spacing: 0em;
		margin-bottom: 30px;
	}
	a {
		border: 2px solid #4285f4;
		border-radius: 4px;
		display: inline-flex;
		align-items: center;
		color: white;
		background: #4285f4;
		box-shadow: 0px 0px 10px 0px #0000001a;

		img {
			padding: 10px;
			background: white;
		}
		span {
			padding: 0 12px;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			letter-spacing: 0em;
		}
	}
	select {
		width: 250px;
		padding: 10px 5px;
		height: 40px;
		margin-right: 10px;
	}
	button {
		border: none;
		background-color: #974cd9;
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		height: auto;
		height: 40px;
		letter-spacing: 0;
		// line-height: 28px;
		padding: 10px 24px;
		cursor: pointer;
	}
}
.gadssw-lower {
	text-align: left;

	h4 {
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;
		letter-spacing: 0em;
	}
	p {
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
		letter-spacing: 0em;
	}
	.gl-img {
		padding: 12px;
		box-shadow: 0px 0px 12px 0px #0000001f;
		img {
			width: 100%;
			height: 100px;
			object-fit: contain;
		}
	}
}
// AGITAL FOOTER
.agital-footer {
    background-color: #181814;
    padding: 60px 100px;
    color: white;
}

.footer-company {
    margin-top: 40px;
    h4 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 35px;
        font-weight: 600;
        color: white;
    }
    ul {
        margin-bottom: 40px;
        li {
            font-size: 14px;
            line-height: 28px;
            a {
                color: #d7d7cc;
                &:hover {
                    text-decoration: underline;
                    color: white;
                }
            }
        }
    }
    .contact-btn {
        a {
            background-color: #974cd9;
            color: white;
            font-size: 16px;
            padding: 15px 35px;
            font-weight: 600;
            &:hover {
                background-color: #974cd9bf;
            }
        }
    }

    .company-social {
        display: flex;
        margin-top: 40px;
        li {
            padding-right: 5px;
            a {
                font-size: 30px;
                color: #ffec1f;
                &:hover {
                    color: #ffec1f;
                }
            }
            img {
                height: 16px;
                width: 18px;
            }
        }
    }
}

 

.footer-navigation {
    a {
        color: #d7d7cc;
        &:hover {
            color: white;
        }
    }
    h4 {
        text-transform: uppercase;
        border-bottom: 1px solid white;
        font-size: 16px;
        line-height: 26px;
        padding-bottom: 10px;
        color: #d7d7cc;
    }

    ul {
        margin-bottom: 28px;
        li {
            font-size: 16px;
            line-height: 26px;
        }
    }
}

.footer-copyright {
    border-top: 1px solid white;
    padding-top: 15px;
    display: flex;
    font-size: 14px;
    svg {
        font-size: 0.8em;
        margin-right: 5px;
    }
    ul {
        display: flex;
        li {
            a {
                padding: 0 10px;
                color: #d7d7cc;
                text-decoration: underline;
                &:hover {
                    color: white;
                }
            }
        }
    }}