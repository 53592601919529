.report-v2-table-wrapper {
	background: white;
	padding-top: 20px;
	.ant-col:nth-child(1) {
		.report-v2-panel-body {
			padding-right: 10px !important;
			padding-top: 10px;
		}
	}
	.ant-col:nth-child(2) {
		.report-v2-panel-body {
			padding-left: 10px !important;
			padding-top: 10px;
		}
	}
	.export-button-wrapper {
		padding-right: 20px;
		margin-bottom: 0;
	}
}
#ntable th.blue_column {
	background-color: #a8d1f3 !important;
}
th.green_column {
	background-color: #c9f1cb !important;
}
th.yellow_column {
	background-color: #ffcc80 !important;
}
td.ant-table-cell.text-center svg {
	color: red;
	margin-right: 5px;
}

#ntable {
	width: 100%;
	text-align: left;
	border-radius: 2px 2px 0 0;
	border-collapse: separate;
	border-spacing: 0;
	border: 1px solid rgba(0, 0, 0, 0.06);
	tr {
		border-bottom: 1px solid #f0f0f0;
		&:last-child {
			border-bottom: none;
		}
	}
	th {
		position: relative;
		color: rgba(0, 0, 0, 0.8509803922);
		font-weight: 500;
		text-align: left;
		background: #fafafa;
		overflow-wrap: break-word;
		text-align: center;
		font-size: 10px;
		text-transform: uppercase;
	}
	td {
		font-size: 12px;
	}
	th,
	td {
		padding: 8px 5px;
		border-bottom: 1px solid #f0f0f0;
		transition: background 0.3s;
		&:not(:last-child) {
			border-right: 1px solid rgba(0, 0, 0, 0.06);
		}
	}
}

#ntable tr:hover {
	background-color: #fafafa;
}
th.ntable-first {
	span {
		margin-left: 5px;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #0e0e0b !important ;
	}
}
.arrow-column {
	padding-left: 20px !important;
	position: relative;
	svg {
		position: absolute;
		left: 20%;
		top: 50%;
		transform: translateY(-50%);
	}
}
.channel-performance-table {
	table{
	width: 100%;
	text-align: left;
	border-radius: 2px 2px 0 0;
	border-collapse: separate;
	border-spacing: 0;
	border: 1px solid rgba(0, 0, 0, 0.06);
	tr{
			border-bottom: 1px solid #f0f0f0;
		&:last-child {
			border-bottom: none;
		}
	}
	th {
		position: relative;
		color: rgba(0, 0, 0, 0.8509803922);
		font-weight: 500;
		text-align: left;
		background: #fafafa;
		overflow-wrap: break-word;
		text-align: center;
		font-size: 10px;
		text-transform: uppercase;
		span{
			margin-left: 3px;
			font-size: 12px;
			svg{
				color:#8dc441 ;
				cursor: pointer;
				
			}
		}
	}
	td {
		font-size: 12px;
		text-align: center;
		position: relative;
		&:nth-child(1) {
			text-align: left;
		}
	}
	th,
	td {
		padding: 8px 5px;
		border-bottom: 1px solid #f0f0f0;
		transition: background 0.3s;
		&:not(:last-child) {
			border-right: 1px solid rgba(0, 0, 0, 0.06);
		}
	}
	}

}
