// CLV SLIDER
.slider-single {
  margin-bottom: 20px;
}
.slider-single-params {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slider-single-caption {
  text-align: right;
  width: 50%;
  font-size: 12px;
}
.slider-single-dropdown {
  width: 50%;
  text-align: center;
}
.slider-single-input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    text-align: center;
  }
  .ant-input-number-disabled,
  .ant-input-number-affix-wrapper-disabled {
    color: rgb(0 0 0 / 58%);
  }
  .ant-row{
    width: 100%;
  }
}
.slider-single-input-profit {
  .ant-input-number-affix-wrapper {
    flex-direction: row-reverse;
  }
}
#slider-wrapper-revenue_3rd,
#slider-wrapper-revenue_6th,
#slider-wrapper-revenue_12th {
  display: none;
}
#slider-wrapper-net_new_customers .ant-input-number-prefix {
  display: none;
}
.ant-slider-rail {
  height: 12px;
}
.ant-slider-track {
  height: 12px;
  background-color: #0E0E0B;
}
.ant-slider-handle {
  width: 25px;
  height: 25px;
  margin-top: -7px;
  background-color: #0E0E0B;
  border: solid 2px #0E0E0B;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open),
.ant-slider-handle:focus,
.ant-slider-handle.ant-tooltip-open {
  border-color: #0E0E0B;
}
.ant-slider:hover .ant-slider-track {
  background-color: #0E0E0B;
}
.revenue_action_buttons {
  padding-top: 20px;
  button {
    background-color: #0E0E0B;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      background-color: #22221E !important;
      border-color: #22221E !important;
      color: white;
    }
    &:focus{
      background-color: #0E0E0B !important;
      color: white !important;
    }
    &:last-child {
      background-color: transparent;
      border-color: #0E0E0B;
      color: #0E0E0B;
      margin-left: 5px;
      &:hover{
        color: white;
      }
      &:focus{
        background-color: transparent !important;
        border-color: #0E0E0B !important;
        color: #0E0E0B !important;
      }
    }
  }
  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
    background: #d4d4d4;
    color: #888888;
    border-color: transparent;
    &:hover{
      background: #bdbdbd !important;
      color: #888888 !important;
      border-color: transparent !important;
    }
  }
}
.cpa-datatable {
  tbody td {
    text-align: center;
  }
}
// MMM LIFT
.lift-title {
  margin-bottom: 10px;
  min-height: 22px;
  h2 {
    margin-bottom: 0;
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }
}
.lift-dropdown {
	display: flex;
	padding: 10px 20px;
	border-left: 5px solid #0E0E0B ;
}
.lift-single-dropdown {
  margin-right: 25px;
  span {
    font-weight: 600;
    font-size: 12px;
  }
  .ant-select {
    margin-left: 10px;
  }
}
.lift-single-buttons {
  button {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      background-color: #689439;
      border-color: #689439;
      color: white;
    }
  }
  .ant-btn[disabled] {
    color: #ffffff !important;
    background-color: #b4b4b4 !important;
  }
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background-color: gray !important;
  }
}
.lift-update {
	background-color: #0E0E0B;
	border-color: #0E0E0B;
	margin-right: 10px;
	&:hover{
		background-color: #22221E !important;
		border-color: #22221E;
	}
}
.lift-reset {
  background-color: gray !important;
}

// MMM INSIGHTS
.insights-wrapper {
  p {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}
.insights-single {
  padding: 5px 0;
}
.insights-actions {
	.insights-title {
		min-width: 70px;
	}
	svg {
		color: #0E0E0B;
		font-size: 14px;
		cursor: pointer;
	}
	.insights-value {
		width: 70px;
		text-align: center;
	}
}
.remarks {
  min-width: 100px;
  padding: 2px;
  text-align: center;
  color: white;
  margin-right: 10px;
}
.remarks-great {
  background-color: #8dc441;
}
.remarks-good {
  background-color: #008000;
}
.remarks-weak {
  background-color: #ffa500;
}
.remarks-bad {
  background-color: #ff0000;
}
.remarks-very-good {
  background-color: #8dc441;
}
.remarks-poor {
	background-color: #ff0000;
  }
.insights-variance {
  strong {
    margin: 0 5px;
  }
}
.insights-modal {
	.ant-modal-body {
		font-size: 13px;
		padding: 30px;
	}
	p {
		margin-bottom: 10px;
	}
	ul {
		padding-left: 30px;
		li {
			list-style-type: square;
		}
	}
	.ant-modal-footer {
		button {
			color: white;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			background-color: #0E0E0B;
			border-color: #0E0E0B;
			&:hover {
				background-color: #22221E;
				border-color: #22221E;
				color: white;
			}
		}
	}
}
.unauthorized-layout{
  height: calc(100vh - 155px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    h2{
      z-index: 20;
      position: relative;
      display: inline-block;
      margin: 0;
      background: #d7d7cc;
      padding: 30px 50px;
      font-size: 22px;
      max-width: 850px;
      text-align: center;
    }
    &::after{
      content: '';
      background-position: center;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      filter: blur(4px);
    }
}
.unauthorized-layout.media_mix_modeling_v2{
  &:after{
    background-image: url('../../Assets/Images/unauth_mmm.png');
  }
}
.unauthorized-layout.customer_lifetime_value{
  &:after{
    background-image: url('../../Assets/Images/unauth_clv.png');
  }
}

.mmm-datatable-options{
  position: absolute;
  right: 40px;
  .datatable-toggle{
    margin-right: 0;
  }
  .export-button-wrapper{
    display: none;
  }
}
.optimized-message{
  font-size: 13px;
  padding-right: 230px;
}