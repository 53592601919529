.organic-scorecard-wrapper {
	display: flex;
	justify-content: center;
}
.organic-scorecard-content {
	width: 70%;
	padding: 180px 100px;
	position: relative;
	background: #f9f9f9;
	box-shadow: 0px 0px 5px 0px #c6c6c6d6;
	min-width: 1100px;
}
.organic-scorecard-header {
	height: 240px;
	background-color: #12a3d8;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}
.os-logo {
	background: white;
	width: 250px;
	padding: 10px;
	border-radius: 18px;
	margin: 60px 0 0 100px;
	img {
		width: 100%;
	}
}
.organic-scorecard-grade {
	margin-bottom: 40px;
}
.osg-left,
.osg-right {
	background: white;
	padding: 40px;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.11999999731779099);
	min-height: 313px;
}
.osg-left {
	text-align: center;
	span {
		font-size: 20px;
	}
	h2 {
		font-size: 80px;
		font-weight: 600;
		margin-bottom: 15px;
		line-height: 1.2;
		border-bottom: 4px solid #f1f1f180;
	}
	h4 {
		font-size: 30px;
		margin-bottom: 5px;
	}
	p {
		font-size: 16px;
	}
}
.osg-right {
	ul {
		li {
			display: flex;
			align-items: center;
			font-size: 16px;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 4px solid #f1f1f180;
			position: relative;
			svg {
				margin-right: 30px;
				font-size: 20px;
			}
			span {
				position: absolute;
				right: 0;
				background: black;
				color: white;
				height: 45px;
				width: 45px;
				border-radius: 50%;
				font-size: 14px;
				text-align: center;
				line-height: 45px;
				font-weight: 600;
			}
			&:last-child {
				padding: 0;
				margin: 0;
				border-bottom: none;
			}
		}
	}
}

.organic-scorecard-single {
	margin-bottom: 40px;
	background: white;
	padding: 40px;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1199999973);
}
.os-single-title {
	margin-bottom: 35px;
	h4 {
		font-size: 25px;
		margin-bottom: 20px;
	}
	span {
		background: black;
		color: white;
		font-size: 16px;
		padding: 5px 30px;
		font-weight: 600;
	}
}
.os-single-header {
	display: flex;
}
.ossh-single {
	border-top: 4px solid #12a3d8;
	margin-right: 35px;
	min-width: 130px;
	h4 {
		font-size: 16px;
		padding-top: 15px;
	}
	h2 {
		font-size: 24px;
	}
	&:nth-child(1){
		border-color: #DBE610;
	}
	&:nth-child(2){
		border-color: #A0C711;
	}&:nth-child(3){
		border-color: #60A512;
	}&:nth-child(4){
		border-color: #3E8C23;
	}&:nth-child(5){
		border-color: #176F34;
	}
}
.os-single-content {
	margin: 20px 0;
	padding-bottom: 20px;
	border-bottom: 4px solid #f1f1f180;
	p {
		font-size: 14px;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	table {
		width: 100%;
		margin-bottom: 30px;
		tr {
			th {
				padding: 12px 10px;
				font-size: 14px;
				text-align: left;
				background-color: white;
			}
			td {
				padding: 12px 10px;
				border-bottom: none;
				font-size: 14px;
			}
			&:nth-child(odd) {
				background-color: #f0f0e6;
			}
		}
	}
}

.insights-relevance{

	top: -100px;
	position: relative;
	margin-bottom: -100px;
}

.os-single-help {
	h4 {
		font-size: 24px;
		font-weight: 600;
	}
	p {
		font-size: 15px;
		margin-bottom: 20px;
	}
	button {
		background-color: #974cd9;
		padding: 12px 24px;
		font-size: 16px;
		border-radius: 5px;
		font-weight: 600;
		color: white;
		box-shadow: none;
		border: none;
		&:hover {
			background-color: #974cd9;
			cursor: pointer;
		}
	}
}
.organic-scorecard-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 30px;
	button {
		border: none;
		background-color: white;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1199999973);
		padding: 10px 30px;
		font-size: 14px;
		border-radius: 5px;
	}
	span {
		background-color: white;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1199999973);
		padding: 1px 30px;
		border-radius: 5px;
		font-size: 14px;
		img {
			width: 130px;
			height: 40px;
		}
	}
}
.organic-scorecard-footer {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: black;
	color: white;
	height: 140px;
	width: 100%;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		color: white;
	}
}
